import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { coachmarkEndpoint as endpoint } from 'providers/endpoints/coachmark';
import Instance from 'providers/instance';

import { actions, putCoachmarkResponse, putCoachmarkUpdateResponse } from 'middleware/actions/coachmark';
import { putErrorResponse } from 'middleware/actions/error';
import { COACHMARK_CHECKED } from 'settings/constants/common';
import { putLocale } from 'middleware/actions/language';

const requestCoachmark = ({ body }) => Instance.post(endpoint.get.getCoachmark, body);
const updateCoachmark = ({ body }) => Instance.post(endpoint.post.updateCoachmark, body);

const customerProfile = state => state.AuthReducer.customerProfile;

function* getCoachmarkSaga() {
  try {
    const { userId: UserId, language } = yield select(customerProfile);
    const body = { UserId };
    const { data } = yield call(requestCoachmark, { body });

    yield put(putCoachmarkResponse(data));
    yield put(putLocale(language));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* updateCoachmarkSaga({ payload }) {
  try {
    const { userId: UserId } = yield select(customerProfile);
    const body = { UserId, submitPressed: COACHMARK_CHECKED, tooltipChecked: payload };
    const { data } = yield call(updateCoachmark, { body });
    yield put(putCoachmarkUpdateResponse(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Coachmark() {
  yield all([takeLatest(actions.GET.COACHMARK, getCoachmarkSaga), takeLatest(actions.POST.POST_COACHMARK, updateCoachmarkSaga)]);
}
