import { actions } from '../actions/sec2u';

const {
  ACTIVATION_SEC2U,
  PUSH_CONTENT_MAYBANK_SECURE2U,
  POOLING_MAYBANK_SECURE2U,
  CHECK_USER_SECURE2U,
  PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN,
  POOLING_MAYBANK_SECURE2U_PRELOGIN,
  ACTIVATION_SEC2U_PRELOGIN,
} = actions.PUT;

const initialState = {
  activationSec2u: {},
  pushContentSecure2u: {},
  poolingSecure2u: {},
  checkUserSecure2u: {},
  activationSec2uPrelogin: {},
  pushContentSecure2uPrelogin: {},
  poolingSecure2uPrelogin: {},
};

const Sec2uReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIVATION_SEC2U:
      return { ...state, activationSec2u: action.res };
    case PUSH_CONTENT_MAYBANK_SECURE2U:
      return { ...state, pushContentSecure2u: action.payload };
    case POOLING_MAYBANK_SECURE2U:
      return { ...state, poolingSecure2u: action.payload };
    case CHECK_USER_SECURE2U:
      return { ...state, checkUserSecure2u: action.payload };
    case ACTIVATION_SEC2U_PRELOGIN:
      return { ...state, activationSec2uPrelogin: action.res };
    case PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN:
      return { ...state, pushContentSecure2uPrelogin: action.payload };
    case POOLING_MAYBANK_SECURE2U_PRELOGIN:
      return { ...state, poolingSecure2uPrelogin: action.payload };
    default:
      return state;
  }
};

export default Sec2uReducer;
