import isEmpty from 'lodash/isEmpty';

import { includes } from 'lodash';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';

import { Icon } from 'components/common';

import browserHistory from 'router/history';

import { get } from 'utils/lodash';
import { store } from 'providers/store';
import { findArrayEntry, sortArrayItems } from 'utils/array';
import { getCorporateURL } from 'utils/home';
import { formatErrorMessage, onlyNumber, removeKeysInObject } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import {
  putResetStopAro,
  getCreditCardDetail,
  getCasaAccountDetail,
  getLoanAccountDetail,
  getCasaTransactionHistory,
  getTimeDepositAccountDetail,
  getWealthAccountDetailAndHistory,
  getTimeDepositAccountBalance,
  getLoanAccountBalance,
  getCreditCardAccountBalance,
  getWealthAccountBalance,
  getCasaAccountBalance,
} from 'middleware/actions/dashboard';

import { routes } from 'router/routes';
import { MENU_ITEMS } from 'settings/constants/menu';
import { ERROR_M9_RESPONSE_CODE, SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';
import { BANCA_TABLE_UNWANTED_KEYS, BALANCE_SUMMARY_ORDERS } from 'settings/constants/array';
import { SAVING_ACCOUNT, CURRENT_ACCOUNT, PARSE_INT_RADIX, DEFAULT_INT_ZERO, CURRENCY_IDR } from 'settings/constants/common';
import {
  WEALTH_CASA,
  WEALTH_BOND_FUND,
  WEALTH_BANCA_FUND,
  WEALTH_MUTUAL_FUND,
  WEALTH_CREDIT_CARD,
  WEALTH_TIME_DEPOSIT,
  BASE_CURRENCY_DISPLAY,
  TIME_DEPOSIT_INSTRUCTION,
  WEALTH_MORTGAGE_AND_LOAN,
  CASA_DEFAULT_HISTORY_VALUE,
  DEFAULT_DEBIT_CREDIT_INDICATOR,
  BOND_TABLE_SUMMARY_HEADER_ITEMS,
  BOND_TABLE_HISTORY_HEADER_ITEMS,
  BANCA_TABLE_SUMMARY_HEADER_ITEMS,
  DEFAULT_CREDIT_CARD_HISTORY_VALUE,
  MUTUAL_FUND_SUMMARY_TABLE_HEADER_ITEMS,
  MUTUAL_FUND_HISTORY_TABLE_HEADER_ITEMS,
  CASA_DETAILS,
  CASA_PAYMENT,
  CASA_TRANSFER,
  CASA_PURCHASE,
  NON_FINANCIAL_USER,
  BALANCE_TYPE_WEALTH,
  DEFAULT_DASHBOARD_BALANCE,
  DASHBOARD_CASA,
  DASHBOARD_LOAN,
  DASHBOARD_WEALTH,
  DASHBOARD_TIME_DEPOSIT,
  DASHBOARD_CREDIT_CARD,
  CASA_TRANSACTION_PRINTABLE,
  BASE_DECIMAL,
  CURRENCY_CODE_IDR,
  TD_MAX_LEN_DISPLAYED_BALANCE,
  TD_MIN_AMOUNT_IDR,
  TD_MIN_AMOUNT_USD,
  TD_PRODUCT_TYPE_DEPOSIT,
  TD_PRODUCT_TYPE_DEPOSIT_IB,
  TD_DDL_ACCOUNT_LABEL_LENGTH,
  TD_DDL_ACCOUNT_BALANCE_LENGTH,
  TD_DEFAULT_XSMALL_WIDTH,
  TD_DEFAULT_SMALL_WIDTH,
  TD_DEFAULT_XLARGE_WIDTH,
  TD_DDL_ACCOUNT_LABEL_MAX_LENGTH,
  DEFAULT_POTENTIAL_GAIN_LOSS_FLAG_VALUE,
  DEFAULT_TEXT_COLOR_RED,
  DEFAULT_TEXT_COLOR_GREEN,
  TD_DEFAULT_XXLARGE_WIDTH,
} from 'settings/constants/dashboard';
import { IconButton } from 'components/common/buttons';

const ACCOUNT_TYPES = new Map([
  ['casa', DASHBOARD_CASA],
  ['loan', DASHBOARD_LOAN],
  ['wealth', DASHBOARD_WEALTH],
  ['timeDeposit', DASHBOARD_TIME_DEPOSIT],
  ['creditCard', DASHBOARD_CREDIT_CARD],
  ['default', ''],
]);

const INDICATOR_COLOURS = new Map([
  [0, 'red'],
  [1, 'black'],
  [2, 'green'],
  [3, 'grey'],
]);

const WEALTH_TYPES = new Map([
  [WEALTH_BOND_FUND, <FormattedMessage id="dashboard.wealth.bonds" />],
  [WEALTH_MUTUAL_FUND, <FormattedMessage id="dashboard.wealth.mutualFund" />],
  [WEALTH_BANCA_FUND, 'Bancassurance'],
  ['default', ''],
]);

export const PRODUCT_TYPE_NISBAH = [
  '221',
  '222',
  '223',
  '224',
  '225',
  '226',
  '227',
  '231',
  '232',
  '233',
  '234',
  '235',
  '236',
  '237',
  '241',
  '242',
  '243',
  '244',
  '245',
  '246',
  '247',
  '251',
  '252',
  '253',
  '254',
  '255',
  '256',
  '257',
];

// 0: Debit(red), 1: Credit(Black), 2: Credit(Green)
export const getIndicatorColour = type => INDICATOR_COLOURS.get(type);

export const getWealthType = (type = 'default') => WEALTH_TYPES.get(type);

export const getAccountType = (type = 'default') => ACCOUNT_TYPES.get(type);

const formatExchangeRates = data => {
  const rates = get(data, 'exchangeRate', []);
  return rates.map(rate => {
    const fromCurrency = get(rate, 'fromCurrencyCodeIso', '');
    const toCurrency = get(rate, 'toCurrencyCodeIso', '');
    const rateDisplay = get(rate, 'rateDisplay', '');

    return `${fromCurrency} ${BASE_CURRENCY_DISPLAY} = ${toCurrency} ${rateDisplay}`;
  });
};

// totalwealth use diffrent api
export const formatTotalWealth = data => {
  const totalWealth = get(data, 'responseData', {});

  const value = BALANCE_TYPE_WEALTH;
  const balanceDisplay = get(totalWealth, 'amountDisp', '');
  const indicator = get(totalWealth, 'debitCreditIndicator', DEFAULT_DEBIT_CREDIT_INDICATOR);

  return {
    value,
    balanceDisplay,
    type: value.toUpperCase(),
    labelClass: getIndicatorColour(indicator),
    label: <FormattedMessage id={`dashboard.${value}.label`} />,
    link: `/dashboard/${value}`,
  };
};

// format data for UI display
export const formatBalance = (data, totalWealth) => {
  const isSuccessTotalBalance =
    get(data, 'responseCode', '') === SUCCESS_RESPONSE_CODE || get(data, 'responseCode', '') === ERROR_M9_RESPONSE_CODE;
  const totalBalance = !isSuccessTotalBalance ? DEFAULT_DASHBOARD_BALANCE : get(data, 'totalBalance', []);
  const exchangeRates = formatExchangeRates(data);

  const updatedBalance = totalBalance.map(balance => {
    const value = get(balance, 'type', '').toLowerCase();
    const indicator = get(balance, 'debitCreditIndicator', DEFAULT_DEBIT_CREDIT_INDICATOR);

    if (value === BALANCE_TYPE_WEALTH) return formatTotalWealth(totalWealth);

    return {
      ...balance,
      value,
      labelClass: getIndicatorColour(indicator),
      label: <FormattedMessage id={`dashboard.${value}.label`} />,
      link: `/dashboard/${value}`,
    };
  });

  return { ...data, exchangeRates, totalBalance: sortArrayItems(updatedBalance, BALANCE_SUMMARY_ORDERS) };
};

export const getCasaAccountDetailPayload = account => {
  const accountNumber = get(account, 'accountNo', '') || get(account, 'accNoDisp', '');
  const branchCode = get(account, 'branchCode', '');
  const currencyCode = get(account, 'currencyCode', '');
  const currencyCodeIso = get(account, 'currencyCodeIso', '');
  const productType = get(account, 'productType', '');
  const userId = get(account, 'userId', '');
  const accountType = get(account, 'accountType', '');

  return {
    userId,
    userAgent,
    accountNumber,
    branchCode,
    currencyCode,
    currencyCodeIso,
    accountType,
    productType,
  };
};

export const getCasaTransactionTypePayload = account => {
  const accountNumber = get(account, 'accountNo', '') || get(account, 'accNoDisp', '');
  const userId = get(account, 'userId', '');

  return {
    userId,
    userAgent,
    accountNumber,
  };
};

export const getCasaTransactionHistoryPayload = account => {
  const accountNumber = get(account, 'accountNo', '') || get(account, 'accNoDisp', '');
  const branchCode = get(account, 'branchCode', '');
  const currencyCode = get(account, 'currencyCode', '');
  const currencyCodeIso = get(account, 'currencyCodeIso', '');
  const userId = get(account, 'userId', '');
  const history = get(account, 'selectedHistory', '');
  const accountType = get(account, 'accountType', '');

  return {
    userId,
    userAgent,
    accountNumber,
    branchCode,
    currencyCode,
    currencyCodeIso,
    history,
    accountType,
  };
};

export const getTimeDepositDetailPayload = ({ userId, accountType, account }) => {
  const accountNumber = get(account, 'accountNo', '') || get(account, 'accNoDisp', '');
  const branchCode = get(account, 'branchCode', '');
  const currencyCode = get(account, 'currencyCode', '');
  const currencyCodeIso = get(account, 'currencyCodeIso', '');
  const productType = get(account, 'productType', '');

  return {
    userId,
    userAgent,
    accountType,
    accountNumber,
    branchCode,
    currencyCode,
    currencyCodeIso,
    productType,
  };
};

export const getTimeDepositProductsPayload = ({ userId }) => {
  return {
    userId,
  };
};

export const getTimeDepositAccountsListPayload = ({ userId }) => {
  return {
    userId,
  };
};

export const getTimeDepositInterestRatePayload = ({ productCode, userId }) => {
  return {
    userId,
    productCode,
  };
};

export const getTimeDepositValidatePayload = ({ amount, balance, currencyCode, productTypeCode, termCode, userId }) => {
  return {
    amount,
    balance,
    currencyCode,
    productTypeCode,
    termCode,
    userId,
  };
};

export const getTimeDepositDoOpenAccountPayload = ({
  cif,
  sourceOfAccount,
  sourceCcy,
  productCode,
  productType,
  tenure,
  productZakat,
  instruction,
  amount,
  currencyCode,
  generatedTrxId,
  referralCode,
  eventCode,
  userId,
  tacValue,
}) => {
  return {
    cif,
    sourceOfAccount,
    sourceCcy,
    productCode,
    productType,
    tenure,
    productZakat,
    instruction,
    amount,
    currencyCode,
    generatedTrxId,
    referralCode,
    eventCode,
    userId,
    tacValue,
  };
};

export const getCreditCardDetailPayload = account => {
  const userId = get(account, 'userId', '');
  const accountType = get(account, 'accountType', '');
  const creditCardNumber = get(account, 'cardNo', '') || onlyNumber(get(account, 'accNo', ''));
  const accountNumber = get(account, 'cardNo', '') || onlyNumber(get(account, 'accNo', ''));
  const history = get(MENU_ITEMS, 'dashboard.creditCard.transactionHistory.ranges.0.value', DEFAULT_CREDIT_CARD_HISTORY_VALUE);

  return {
    userId,
    userAgent,
    creditCardNumber,
    accountType,
    accountNumber,
    history,
  };
};

export const getLoanAccountDetailPayload = account => {
  const userId = get(account, 'userId', '');
  const accountNumber = get(account, 'accountNo', '') || get(account, 'accNoDisp', '');
  const branchCode = get(account, 'branchCode', '');
  const currencyCode = get(account, 'currencyCode', '');
  const currencyCodeIso = get(account, 'currencyCodeIso', '');
  const productType = get(account, 'productType', '');
  const accountType = get(account, 'accountType', '');

  return {
    userId,
    userAgent,
    accountNumber,
    branchCode,
    currencyCode,
    currencyCodeIso,
    accountType,
    productType,
  };
};

export const getBancaDetails = card => {
  return [
    { title: <FormattedMessage id="dashboard.wealth.totalValue" />, info: get(card, 'amountDisp', '') },
    { title: <FormattedMessage id="dashboard.wealth.totalProduct" />, info: get(card, 'totalProduct', DEFAULT_INT_ZERO) },
  ];
};

export const getCreditCardDetails = card => [
  { title: <FormattedMessage id="dashboard.cc.ccHolderName" />, info: get(card, 'cardHolderName', '') },
  { title: <FormattedMessage id="dashboard.cc.availableCredit" />, info: get(card, 'availableCredit', '') },
  { title: <FormattedMessage id="dashboard.cc.creditLimit" />, info: get(card, 'creditLimit', '') },
  { title: <FormattedMessage id="dashboard.cc.currentBalance" />, info: get(card, 'billingAmount', '') },
  { title: <FormattedMessage id="dashboard.cc.billingCycle" />, info: get(card, 'billingCycle', '') },
  { title: <FormattedMessage id="dashboard.cc.outstandingAuthItem" />, info: get(card, 'outstandingAuthItems', '') },
  { title: <FormattedMessage id="dashboard.cc.outstandingAuthAmount" />, info: get(card, 'outstandingAuthAmount', '') },
];

export const getCasaAccountSelection = ({ selectedType, savingAccountDetail, currentAccountDetail }) => {
  switch (selectedType) {
    case SAVING_ACCOUNT:
      return savingAccountDetail;
    case CURRENT_ACCOUNT:
      return currentAccountDetail;
    default:
      return {};
  }
};

export const formatTransactionOptions = ({ name, options, LabelComponent }) =>
  !isEmpty(options) && options.map(option => ({ ...option, name, label: <LabelComponent option={option} /> }));

export const getTimeDepositDetails = (data, isSyariah, isNisbah) => [
  {
    title: <FormattedMessage id="dashboard.td.details.branch" />,
    info: get(data, 'branch', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.principalAmount" />,
    info: get(data, 'principalAmount', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.holdAmount" />,
    info: get(data, 'holdAmount', ''),
  },
  {
    title: (
      <FormattedMessage
        id={isSyariah && isNisbah ? 'dashboard.td.details.profitSharingRatio' : `dashboard.td.details.indicativeEquivalentRate`}
      />
    ),
    info: get(data, 'interestRate', ''),
  },
  {
    title: (
      <FormattedMessage id={isSyariah ? `dashboard.td.details.accruedIndicativeEquivalentRate` : `dashboard.td.details.accruedInterest`} />
    ),
    info: get(data, 'accruedInterest', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.term" />,
    info: get(data, 'depositPeriod', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.uponMaturity" />,
    info: get(data, 'maturityInstructionDisplay', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.placementDate" />,
    info: get(data, 'openedDate', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.maturityDate" />,
    info: get(data, 'maturityDate', ''),
  },
  {
    title: (
      <FormattedMessage
        id={isSyariah ? 'dashboard.td.details.nextIndicativeEquivalentRatePaymentDate' : 'dashboard.td.details.nextPaymentDate'}
      />
    ),
    info: get(data, 'nextInterestPaymentDate', ''),
  },
];

export const getTimeDepositDetailsHeaders = data => [
  {
    title: <FormattedMessage id="dashboard.td.details.balance" />,
    info: get(data, 'principalAmount', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.balanceIDR" />,
    info: get(data, 'equivalentAmount', ''),
  },
];

export const getLoanDetail = (loan, isSyariah) => [
  { title: <FormattedMessage id="dashboard.loan.totalLoan" />, info: get(loan, 'currentPrincipalAmount', '') },
  { title: <FormattedMessage id="dashboard.loan.remainingOutstanding" />, info: get(loan, 'loanOutstanding', '') },
  { title: <FormattedMessage id="dashboard.loan.loanPeriod" />, info: get(loan, 'loanTenor', '') },
  { title: <FormattedMessage id="dashboard.loan.maturity" />, info: get(loan, 'maturityDate', '') },
  {
    title: <FormattedMessage id={isSyariah ? `dashboard.loan.latePaymentCharge` : `dashboard.loan.lateChargeFee`} />,
    info: get(loan, 'lateChargeRate', ''),
  },
];

export const getCasaDetails = account => [
  { title: <FormattedMessage id="dashboard.casa.beginningBalance" />, info: get(account, 'beginingBalance', '') },
  { title: <FormattedMessage id="dashboard.casa.availableBalance" />, info: get(account, 'availableBalance', '') },
  { title: <FormattedMessage id="dashboard.casa.holdBalance" />, info: get(account, 'holdAmount', '') },
];

export const getCasaHeader = account => {
  const isIDR = get(account, 'currency', CURRENCY_IDR) === CURRENCY_IDR;
  return [
    { title: <FormattedMessage id="dashboard.casa.balance" />, info: get(account, 'balance', '') },
    !isIDR ? { title: <FormattedMessage id="dashboard.casa.balanceIDR" />, info: get(account, 'equivalentAmount', '') } : {},
  ];
};

export const getTdHeader = detail => {
  const isIDR = get(detail, 'currency', CURRENCY_IDR) === CURRENCY_IDR;
  const summary = get(detail, 'ibTDAccountSummaryBean', {});
  const balance = get(summary, 'balance', '');

  return [
    { title: <FormattedMessage id="dashboard.casa.balance" />, info: balance },
    !isIDR ? { title: <FormattedMessage id="dashboard.casa.balanceIDR" />, info: get(detail, 'equivalentAmount', '') } : {},
  ];
};

export const getWealthHeaderItems = (type, isDetail) => {
  const handler = {
    [WEALTH_MUTUAL_FUND]: () => (!isDetail ? MUTUAL_FUND_SUMMARY_TABLE_HEADER_ITEMS : MUTUAL_FUND_HISTORY_TABLE_HEADER_ITEMS),
    [WEALTH_BOND_FUND]: () => (!isDetail ? BOND_TABLE_SUMMARY_HEADER_ITEMS : BOND_TABLE_HISTORY_HEADER_ITEMS),
    [WEALTH_BANCA_FUND]: () => (!isDetail ? BANCA_TABLE_SUMMARY_HEADER_ITEMS : []),
    default: () => [],
  };

  return (handler[type] || handler.default)();
};

// Format the data inside Banca's table
export const formatBancaDetails = items =>
  Array.isArray(items) &&
  items.map(item => {
    // Remove unnecessary keys inside object
    const initialItem = removeKeysInObject(item, BANCA_TABLE_UNWANTED_KEYS);

    // Retrieve necessary information
    const policyHolderName = get(item, 'policyHolder', '');
    const currencyCode = get(initialItem, 'currencyCodeIso', '');
    const nav = get(initialItem, 'navDisplay', '');
    const balanceUnit = `${currencyCode} ${get(initialItem, 'balanceUnit', '')}`;

    // Remove unwanted keys after retriving data
    const unwantedKeys = ['nav', 'navDisplay', 'currencyCodeIso', 'policyHolder'];
    const finalItem = removeKeysInObject(initialItem, unwantedKeys);

    return { ...finalItem, policyHolderName, nav, balanceUnit };
  });

// NOTE: this HOC are used to check that component condition for when user type in /dashboard/cc/details
//       - it will redirect back to /dashboard/cc/
export const withRedirect = Component => {
  // NOTE: get current path and remove details,summary to go back to accountType dashboard
  const dashboardAccountPath = browserHistory.location.pathname.replace('/details', '').replace('/summary', '');

  // NOTE: When user enter component generated by this function using browser instead of route. replace url back to accountType dashboard
  browserHistory.replace(dashboardAccountPath);

  return Component;
};

export const formatCasaTransactions = (data = []) =>
  data.map(item => {
    const amount = get(item, 'debitCreditAmount', '');
    const indicator = get(item, 'debitCreditIndicator', 1);
    const extraStyle = getIndicatorColour(indicator);
    const newAmount = <span className={extraStyle}>{amount}</span>;

    return { ...item, debitCreditAmount: newAmount };
  });

export const formatCasaM2UTransactions = (data = [], handleDownload = {}) =>
  data.map(item => {
    const serviceName = get(item, 'serviceName', '');
    const toAccountHolderName = get(item, 'toAccountHolderName', '');
    const desc = (
      <>
        {serviceName}
        <br />
        {toAccountHolderName}
      </>
    );
    const amount = get(item, 'amount', '');
    const indicator = get(item, 'debitCreditIndicator', 1);
    const extraStyle = getIndicatorColour(indicator);
    const newAmount = <span className={extraStyle}>{amount}</span>;
    const transactionID = get(item, 'transactionID', '');
    const printAble = get(item, 'printAble', '0');
    const download = (
      <IconButton onClick={() => handleDownload(transactionID)}>
        <Icon isAssetIcon={false} type="download" />
      </IconButton>
    );

    return {
      ...item,
      description: desc,
      amount: newAmount,
      download: printAble === CASA_TRANSACTION_PRINTABLE ? download : null,
    };
  });

export const hasStopAroMenu = instruction => {
  const { PRINCIPAL_ROLLOVER, PRINCIPAL_INTEREST_ROLLOVER } = TIME_DEPOSIT_INSTRUCTION;

  // parse to int because API could return it as String
  const parsedInstruction = parseInt(instruction, PARSE_INT_RADIX);

  return includes([PRINCIPAL_ROLLOVER, PRINCIPAL_INTEREST_ROLLOVER], parsedInstruction);
};

export const formatCcTransactionData = (data = []) =>
  Array.isArray(data) &&
  data.map(item => {
    const amount = get(item, 'debitCreditAmount', '');
    const indicator = get(item, 'debitCreditIndicator', 1);
    const extraStyle = getIndicatorColour(indicator);
    const newAmount = <span className={extraStyle}>{amount}</span>;

    return { ...item, amount: newAmount };
  });

export const formatInitiateStopAroDetail = data => {
  const responseCode = get(data, 'responseCode', '');
  const tacBean = get(data, 'tacBean', {});
  const accountSummaryBean = get(data, 'tcdAccSummaryBean', {});
  const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
  const errorCode = !isSuccess && responseCode;

  return { isInitiated: true, errorCode, isSuccess, tacBean, accountSummaryBean };
};

export const formatStopAroTacDetail = data => {
  const responseCode = get(data, 'responseCode', '');
  const tacViewBean = get(data, 'tacViewBean', {});
  const tacSentDateTime = get(data, 'tacSentDateTime', '');
  const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
  const errorCode = !isSuccess && responseCode;

  return { isTacRequested: true, errorCode, isSuccess, tacViewBean, tacSentDateTime };
};

export const formatConfirmStopAroDetail = data => {
  const responseCode = get(data, 'responseCode', '');
  const referenceNumber = get(data, 'referenceNo', {});
  const transactionId = get(data, 'transactionID', '');
  const transactionDateTime = get(data, 'transDateTime', '');
  const transactionDetail = get(data, 'statusInformationMap', {});
  const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
  const errorCode = !isSuccess && responseCode;

  return { isConfirmed: true, errorCode, isSuccess, referenceNumber, transactionId, transactionDateTime, transactionDetail };
};

export const formatStopAroReceipt = data => {
  const responseCode = get(data, 'responseCode', '');
  const receiptPdf = get(data, 'receiptPdf', '');
  const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
  const errorCode = !isSuccess && responseCode;

  return { errorCode, isSuccess, receiptPdf };
};

export const getStopAroContents = ({ accountDetail, transactionDetail }) => [
  {
    title: <FormattedMessage id="dashboard.td.details.transactionDate" />,
    info: get(transactionDetail, 'transactionDateTime', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.maturityDate" />,
    info: get(accountDetail, 'maturityDate', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.uponMaturity" />,
    info: get(accountDetail, 'maturityInstructionDisplay', ''),
  },
  {
    title: <FormattedMessage id="dashboard.td.details.refId" />,
    info: get(transactionDetail, 'referenceNumber', ''),
  },
];

export const getWealthDetailTitle = (type, detail) => {
  const handler = {
    [WEALTH_MUTUAL_FUND]: () => get(detail, 'productName', ''),
    // eslint-disable-next-line react/display-name
    [WEALTH_BOND_FUND]: () => <FormattedMessage id="dashboard.wealth.bonds" />, // No translation for bonds
    // eslint-disable-next-line react/display-name
    [WEALTH_BANCA_FUND]: () => {
      const productName = get(detail, 'productName', '');
      const accountNumber = get(detail, 'accNoDisp', '');

      return (
        <div>
          {productName}
          <span className="f7 pl2 gray">{accountNumber}</span>
        </div>
      );
    },
    default: () => '',
  };

  return (handler[type] || handler.default)();
};

export const getMutualFundWealthDetails = detail => {
  const navDate = get(detail, 'navDateDisp', '');
  return [
    { title: <FormattedMessage id="dashboard.wealth.sid" />, info: get(detail, 'sid', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.fundManager" />, info: get(detail, 'fundManager', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.productType" />, info: get(detail, 'productType', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.currency" />, info: get(detail, 'currency', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.outstandingUnit" />, info: get(detail, 'outstandingUnit', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.navAsOf" values={{ date: navDate }} />, info: get(detail, 'nav', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.pendingSubscription" />, info: get(detail, 'pendingSubc', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.pendingRedemption" />, info: get(detail, 'pendingRedemption', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.pendingSwitching" />, info: get(detail, 'pendingSwitching', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.growthFund" />, info: get(detail, 'growthFund', '') },
  ];
};

export const getPotentialGainLoss = detail => {
  const amountPotentialGainLoss = get(detail, 'potentialGainLoss', '');
  const percentageGainLoss = get(detail, 'percentageGainLoss', '');

  const potentialGainLossDisplay = amountPotentialGainLoss === '' ? '' : `${amountPotentialGainLoss} (${percentageGainLoss})`;
  return potentialGainLossDisplay;
};

export const getPotentialGainLossValueColor = detail => {
  const flag = get(detail, 'flagGainLoss', '');
  const valueColor = flag === DEFAULT_POTENTIAL_GAIN_LOSS_FLAG_VALUE ? DEFAULT_TEXT_COLOR_RED : DEFAULT_TEXT_COLOR_GREEN;
  return valueColor;
};

// TODO: Translation
export const getBondWealthDetails = detail => [
  { title: <FormattedMessage id="dashboard.wealth.sid" />, info: get(detail, 'sid', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.productCode" />, info: get(detail, 'productCode', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.productName" />, info: get(detail, 'productName', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.currency" />, info: get(detail, 'currency', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.coupon" />, info: get(detail, 'coupon', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.couponPeriod" />, info: get(detail, 'couponPeriod', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.couponDate" />, info: get(detail, 'couponDate', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.tenor" />, info: get(detail, 'tenor', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.maturityDate" />, info: get(detail, 'maturityDateDisp', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.startDateEarlyRedemption" />, info: get(detail, 'startDateDisp', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.endDateEarlyRedemption" />, info: get(detail, 'endDateDisp', '') },
  { title: <FormattedMessage id="dashboard.wealth.details.settlementDate" />, info: get(detail, 'settlementDateDisp', '') },
  // {
  //   title: <FormattedMessage id="dashboard.wealth.details.gainLossPotency" />,
  //   info: getPotentialGainLoss(detail),
  //   valueColor: getPotentialGainLossValueColor(detail),
  // },
];

// TODO: Translation
export const getBancaWealthDetails = detail => {
  const navDate = get(detail, 'navDateDisp', '');
  return [
    { title: <FormattedMessage id="dashboard.wealth.details.payeeName" />, info: get(detail, 'payeeName', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.policyHolder" />, info: get(detail, 'policyHolder', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.fundName" />, info: get(detail, 'fundName', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.currency" />, info: get(detail, 'currency', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.balanceUnit" />, info: get(detail, 'balanceUnit', '') },
    { title: <FormattedMessage id="dashboard.wealth.details.navAsOf" values={{ date: navDate }} />, info: get(detail, 'nav', '') },
  ];
};

export const getWealthDetails = (detail, type) => {
  const handler = {
    [WEALTH_MUTUAL_FUND]: () => getMutualFundWealthDetails(detail),
    [WEALTH_BOND_FUND]: () => getBondWealthDetails(detail),
    [WEALTH_BANCA_FUND]: () => getBancaWealthDetails(detail),
    default: () => '',
  };
  return (handler[type] || handler.default)();
};

export const navigateToAccount = (accountType, product, userId, dispatch, history) => {
  const handleWealthNavigation = () => {
    const wealthDetailsPath = get(routes, 'dashboard.wealthDetails', '');

    const id = get(product, 'id', '');
    const isIpo = get(product, 'isIpo', DEFAULT_INT_ZERO);

    dispatch(getWealthAccountDetailAndHistory({ userId, id, isIpo }, accountType));
    // `ignore` are being used at utils/dashboard.js
    history.push(wealthDetailsPath, { ignore: true, accountType, id, isIpo });
  };

  const handler = {
    [WEALTH_CASA]: () => {
      const casaDetailsPath = get(routes, 'dashboard.casaDetails', '');

      const payload = getCasaAccountDetailPayload({ ...product, userId });
      const transactionPayload = getCasaTransactionHistoryPayload({ ...product, userId, selectedHistory: CASA_DEFAULT_HISTORY_VALUE });

      const type = get(product, 'accountType', '');
      const accNo = get(product, 'accNoDisp', '');

      dispatch(getCasaTransactionHistory(transactionPayload));
      dispatch(getCasaAccountDetail(payload));
      history.push(casaDetailsPath, { accountType: type, accNo });
    },
    [WEALTH_TIME_DEPOSIT]: () => {
      const timeDepositDetailsPath = get(routes, 'dashboard.timeDepositDetails', '');

      const payload = getTimeDepositDetailPayload({ userId, accountType, account: product });

      dispatch(putResetStopAro());
      dispatch(getTimeDepositAccountDetail(payload));
      history.push(timeDepositDetailsPath);
    },
    [WEALTH_MUTUAL_FUND]: () => handleWealthNavigation(),
    [WEALTH_BANCA_FUND]: () => handleWealthNavigation(),
    [WEALTH_BOND_FUND]: () => handleWealthNavigation(),
    [WEALTH_MORTGAGE_AND_LOAN]: () => {
      const loanDetailsPath = get(routes, 'dashboard.loanDetails', '');

      const payload = getLoanAccountDetailPayload({ ...product, userId });

      dispatch(getLoanAccountDetail(payload));
      history.push(loanDetailsPath);
    },
    [WEALTH_CREDIT_CARD]: () => {
      const creditCardDetailsPath = get(routes, 'dashboard.creditCardDetails', '');

      const accountNumber = get(product, 'accNo', '');
      const accountNumberDisplay = get(product, 'accNoDisp', '');

      const creditCardDetailPayload = getCreditCardDetailPayload({ userId, accountType, ...product });
      dispatch(getCreditCardDetail(creditCardDetailPayload));

      history.push(creditCardDetailsPath, { accountNumber, accountNumberDisplay });
    },
  };
  return handler[accountType]();
};

export const handleCasaNavigation = ({
  type,
  userId,
  account,
  history,
  onDrawerOpen,
  formatMessage,
  setSelectedAccount,
  isTransactionBlocked,
}) => {
  const casaDetailsPath = get(routes, 'dashboard.casaDetails', '');
  const transferPath = get(routes, 'transaction.transfer', '');
  const paymentPath = get(routes, 'transaction.payment', '');
  const purchasePath = get(routes, 'transaction.purchase', '');
  const currency = get(account, 'currency', '') || get(account, 'currencyCodeIso', '');

  // accountNo from AccountSection and account from AccountDetailSection
  const accountNo = get(account, 'accountNo', '') || get(account, 'accountNumber', '') || '';

  const handler = {
    [CASA_DETAILS]: () => {
      const detailPayload = getCasaAccountDetailPayload({ ...account, userId });
      store.dispatch(getCasaAccountDetail(detailPayload));
      history.push(casaDetailsPath);
      setSelectedAccount(account);
    },
    [CASA_PAYMENT]: () => {
      // block user from proceed with route and show error drawer with mapped error message
      if (isTransactionBlocked) return onDrawerOpen(formatErrorMessage(formatMessage, 'HALF_KYC_NON_FINANCIAL'));
      if (currency !== CURRENCY_IDR) return onDrawerOpen(formatErrorMessage(formatMessage, 'CURRENCY_NOT_ALLOWED_FOR_TRANSACTION'));
      // send account number to Payment page so that can be matched with list of source account at payment
      return history.push(paymentPath, { accountNo });
    },
    [CASA_TRANSFER]: () => {
      // block user from proceed with route and show error drawer with mapped error message
      if (isTransactionBlocked) return onDrawerOpen(formatErrorMessage(formatMessage, 'HALF_KYC_NON_FINANCIAL'));
      // send account number to Transfer page so that can be matched with list of source account at transfer
      return history.push(transferPath, { accountNo });
    },
    [CASA_PURCHASE]: () => {
      // block user from proceed with route and show error drawer with mapped error message
      if (isTransactionBlocked) return onDrawerOpen(formatErrorMessage(formatMessage, 'HALF_KYC_NON_FINANCIAL'));
      if (currency !== CURRENCY_IDR) return onDrawerOpen(formatErrorMessage(formatMessage, 'CURRENCY_NOT_ALLOWED_FOR_TRANSACTION'));
      // send account number to Purchase page so that can be matched with list of source account at purchase
      return history.push(purchasePath, { accountNo });
    },
    default: () => null,
  };
  return (handler[type] || handler.default)();
};

export const getEmptyAccountText = (accountType, locale) => {
  const noAccountText = <FormattedMessage id={`dashboard.${accountType.toLowerCase()}.noAccount`} />;

  const handler = {
    [DASHBOARD_CASA]: noAccountText,
    [DASHBOARD_TIME_DEPOSIT]: [noAccountText],
    [DASHBOARD_CREDIT_CARD]: [
      <a href={getCorporateURL(locale).applycc} target="_blank" rel="noreferrer" className="mr1 no-underline">
        <FormattedMessage id="buttonText.clickHere" />
      </a>,
      noAccountText,
    ],
    [DASHBOARD_LOAN]: [
      <a href={getCorporateURL(locale).applyloan} target="_blank" rel="noreferrer" className="mr1 no-underline">
        <FormattedMessage id="buttonText.clickHere" />
      </a>,
      noAccountText,
    ],
    [DASHBOARD_WEALTH]: noAccountText,
    default: <FormattedMessage id="dashboard.casa.noAccount" />,
  };

  return handler[accountType] || handler.default;
};

export const getAccountBalance = (userId, accountType, dispatch) => {
  const handler = {
    [DASHBOARD_CASA]: () => dispatch(getCasaAccountBalance({ userId, accountType })),
    [DASHBOARD_TIME_DEPOSIT]: () => dispatch(getTimeDepositAccountBalance({ userId, accountType })),
    [DASHBOARD_CREDIT_CARD]: () => dispatch(getCreditCardAccountBalance({ userId, accountType })),
    [DASHBOARD_LOAN]: () => dispatch(getLoanAccountBalance({ userId, accountType })),
    [DASHBOARD_WEALTH]: () => dispatch(getWealthAccountBalance({ userId, accountType })),
    default: () => null,
  };

  return handler[accountType] || handler.default;
};

export const getDepositDetail = (productTypeId, term, interestRate, instructionAtMaturityTitle, akad, zakat) => {
  const conventional = [
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.term" />,
      info: term,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.interestRate" />,
      info: interestRate,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.instructionAtMaturity" />,
      info: instructionAtMaturityTitle,
    },
  ];
  const iB = [
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.term" />,
      info: term,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.interestRateIB" />,
      info: interestRate,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.instructionAtMaturity" />,
      info: instructionAtMaturityTitle,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.akad" />,
      info: akad,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.zakat" />,
      info: zakat,
    },
  ];
  return productTypeId === TD_PRODUCT_TYPE_DEPOSIT ? conventional : iB;
};

export const getDepositDetailResult = (productTypeId, term, interestRate, instructionAtMaturityTitle, akad, zakat, depositAccNo, refNo) => {
  const conventional = [
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.term" />,
      info: term,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.interestRate" />,
      info: interestRate,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.instructionAtMaturity" />,
      info: instructionAtMaturityTitle,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.depositAccountNumber" />,
      info: depositAccNo,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.referenceNo" />,
      info: refNo,
    },
  ];
  const iB = [
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.term" />,
      info: term,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.interestRateIB" />,
      info: interestRate,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.instructionAtMaturity" />,
      info: instructionAtMaturityTitle,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.depositAccountNumber" />,
      info: depositAccNo,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.akad" />,
      info: akad,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.zakat" />,
      info: zakat,
    },
    {
      title: <FormattedMessage id="dashboard.td.confirmTac.referenceNo" />,
      info: refNo,
    },
  ];
  return productTypeId === TD_PRODUCT_TYPE_DEPOSIT ? conventional : iB;
};

export const getIsNisbah = productType => {
  const nisbah = PRODUCT_TYPE_NISBAH.filter(type => productType === type);

  return !isEmpty(nisbah);
};

export const getReceiverDetail = transactionAmount => {
  return {
    transactionAmount,
    receiverName: '',
    receiverAccount: '',
    transactionType: '',
  };
};

export const getResultDetailTAC = (statusOpenAccount, transactionID) => {
  return {
    isTransactionSuccessful: statusOpenAccount,
    transactionID,
  };
};

export const getSenderAccountDetails = (senderAccount, labelId, isFullScreen) => {
  const accountName = get(senderAccount, 'productName', '');
  const accountNumber = get(senderAccount, 'accountNo', '');
  const accountBalance = get(senderAccount, 'balanceDisplay', '');

  return [
    {
      title: (
        <>
          <FormattedMessage id={labelId} />
          {isFullScreen && <br />}
          <strong className={!isFullScreen && `ml1`}>{accountName}</strong>
        </>
      ),
      info: (
        <>
          <FormattedMessage id="transaction.transfer.availableBalance" />
          {isFullScreen && <br />}
          <span className="ml1 green">{accountBalance}</span>
        </>
      ),
    },
    { title: accountNumber, info: '' },
  ];
};

export const truncateAccountBalanceDisplay = balanceText => {
  return balanceText.length > TD_MAX_LEN_DISPLAYED_BALANCE ? balanceText.slice(0, TD_MAX_LEN_DISPLAYED_BALANCE) : balanceText;
};

export const truncateAccountLabelDisplay = (accountLabel, currentWindowWidth) => {
  let prefixNumber = TD_DDL_ACCOUNT_LABEL_MAX_LENGTH;
  switch (true) {
    case currentWindowWidth <= TD_DEFAULT_XSMALL_WIDTH:
      prefixNumber = TD_DDL_ACCOUNT_LABEL_MAX_LENGTH - 8;
      break;
    case currentWindowWidth > TD_DEFAULT_XSMALL_WIDTH && currentWindowWidth <= TD_DEFAULT_SMALL_WIDTH:
      prefixNumber = TD_DDL_ACCOUNT_LABEL_MAX_LENGTH - 6;
      break;
    case currentWindowWidth > TD_DEFAULT_SMALL_WIDTH && currentWindowWidth <= TD_DEFAULT_XLARGE_WIDTH:
      prefixNumber = TD_DDL_ACCOUNT_LABEL_MAX_LENGTH - 4;
      break;
    case currentWindowWidth > TD_DEFAULT_XLARGE_WIDTH && currentWindowWidth <= TD_DEFAULT_XXLARGE_WIDTH:
      prefixNumber = TD_DDL_ACCOUNT_LABEL_MAX_LENGTH - 2;
      break;
    default:
      break;
  }
  return accountLabel.length > prefixNumber ? accountLabel.slice(0, prefixNumber) : accountLabel;
};

export const getTDAccountDetailDynamicFields = ({ isSmallScreen, getAccounts, fndAccount, currentWindowWidth }) => {
  const getAccountOptions = getAccounts.map((account, idx) => {
    const accountName = get(account, 'productName', '');
    const accountNo = get(account, 'accountNo', '');
    const balance = get(account, 'balanceDisplay', '');
    const currency = get(account, 'currencyCodeIso', '');
    const accountLabel = `${accountName} - ${accountNo} ${balance}`;

    return {
      label: accountLabel,
      value: `${accountNo}-${currency}`,
      id: `account-${idx}`,
    };
  });
  const accName = get(findArrayEntry(getAccounts, 'accountNo', get(fndAccount, 'accountNo', '')), 'productName', '');
  const balanceDisplay = get(fndAccount, 'balanceDisplay', '');
  const truncatedAccName =
    currentWindowWidth > TD_DEFAULT_XSMALL_WIDTH &&
    currentWindowWidth < TD_DEFAULT_XXLARGE_WIDTH &&
    accName.length > TD_DDL_ACCOUNT_LABEL_MAX_LENGTH
      ? `${truncateAccountLabelDisplay(accName, currentWindowWidth)}...`
      : accName;
  // const ddlLabel = `${accName} - ${get(fndAccount, 'accountNo', '')}`;
  const ddlLabel = `${truncatedAccName} - ${get(fndAccount, 'accountNo', '')}`;

  const foundAccount = {
    accountNumber: get(fndAccount, 'accountNo', ''),
    balance:
      isSmallScreen ||
      (isSmallScreen && ddlLabel.length > TD_DDL_ACCOUNT_LABEL_LENGTH && balanceDisplay.length > TD_DDL_ACCOUNT_BALANCE_LENGTH) ||
      (currentWindowWidth > TD_DEFAULT_XSMALL_WIDTH &&
        currentWindowWidth < TD_DEFAULT_SMALL_WIDTH &&
        ddlLabel.length > TD_DDL_ACCOUNT_LABEL_LENGTH &&
        balanceDisplay.length > TD_DDL_ACCOUNT_BALANCE_LENGTH) ||
      (currentWindowWidth > TD_DEFAULT_SMALL_WIDTH &&
        currentWindowWidth < TD_DEFAULT_XLARGE_WIDTH &&
        ddlLabel.length > TD_DDL_ACCOUNT_LABEL_MAX_LENGTH &&
        balanceDisplay.length > TD_DDL_ACCOUNT_BALANCE_LENGTH)
        ? `${truncateAccountBalanceDisplay(balanceDisplay)}...`
        : balanceDisplay,
    dropdownLabel: ddlLabel,
    realBalance: balanceDisplay,
  };

  return [{ name: 'fromAccount', options: getAccountOptions, foundAccount }];
};

export const getTDProductDetailDynamicFields = ({ balance, getTerm, getCurrency, getInstruction, selectedCurrency }) => {
  const getTermOptions = getTerm.map((term, idx) => {
    const termName = get(term, 'name', '');
    const termCode = get(term, 'code', '');

    return {
      label: termName,
      value: termCode,
      id: `term-${idx}`,
    };
  });

  const getInstructionOptions = getInstruction.map((instruction, idx) => {
    const instructionCode = get(instruction, 'code', '');
    const instructionName = get(instruction, 'name', '');

    return {
      label: instructionName,
      value: instructionCode,
      id: `instruction-${instructionCode}`,
    };
  });

  const getCurrencyOptions = getCurrency.map((curr, idx) => {
    const ccyName = get(curr, 'name', '');
    const ccyCode = get(curr, 'code', '');

    return {
      label: ccyName,
      value: ccyCode,
      id: `currency-${idx}`,
    };
  });

  const matchData = findArrayEntry(getCurrency, 'name', selectedCurrency);
  const minimumAmount = parseInt(
    get(matchData, 'minimum', selectedCurrency === CURRENCY_CODE_IDR ? TD_MIN_AMOUNT_IDR : TD_MIN_AMOUNT_USD),
    BASE_DECIMAL
  );

  return [
    { name: 'term', options: getTermOptions },
    { name: 'instructionAtMaturity', options: getInstructionOptions },
    {
      name: 'amountDeposit',
      isEditable: true,
      optionField: {
        name: 'currency',
        options: getCurrencyOptions,
        isTextField: true,
        readOnly: true,
      },
      validationRules: [
        { type: 'required', isValidateRequired: true, message: 'dashboard.td.errorMessage.required' },
        {
          type: 'minAmount',
          isValidateRequired: true,
          value: minimumAmount,
          message: 'dashboard.td.errorMessage.minAmount',
        },
        {
          type: 'maxAmount',
          isValidateRequired: true,
          value: balance,
          message: 'dashboard.td.errorMessage.maxAmount',
        },
      ],
    },
  ];
};

export const getTDTacResult = (isResultSuccess, isTransactionSuccess, statusCode, statusMessage) => {
  return {
    isFavAccount: false,
    isResultSuccess,
    isTransactionSuccess,
    statusCode,
    statusMessage,
  };
};

export const findAccountTimeDeposit = (accounts = [], fromAccountWithCurrency = '') => {
  const [accountNumber, currency] = fromAccountWithCurrency.split('-');
  return accounts.find(account => {
    if (currency) return account.currencyCodeIso === currency && account.accountNo === accountNumber;
    return account.accountNo === accountNumber;
  });
};
