import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, Link as MaterialLink } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import StepOne from 'assets/images/secure2u/step_one.png';
import StepTwo from 'assets/images/secure2u/step_two.png';
import StepThree from 'assets/images/secure2u/step_three.png';
import parse from 'html-react-parser';
import { RESEND_TAC_TIMER } from '../../../../../settings/constants/tac';
import { CountdownTimer } from '../../../index';

const Secure2uStepFinalSection = ({ isShowTimer, onTimerComplete, showPopup, backRetrySecure2u }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container sm={12} md={12}>
      <Grid item xs={12} sm={6} md={5} container className="title">
        <Typography className="tac--title">
          <strong>
            <FormattedMessage id="secure2u.dontClose" />
          </strong>
          <br />
          {parse(formatMessage({ id: 'secure2u.makeSureActive' }))}
          <br />
          <div style={{ marginTop: '5px' }}>
            <FormattedMessage id="secure2u.completeGuide" />
            <strong>
              &nbsp;
              <Button
                onClick={showPopup}
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                  fontSize: '15px',
                  textTransform: 'lowercase',
                  background: 'none',
                  border: 'none',
                  marginLeft: '-8px',
                  marginTop: '-2px',
                  fontWeight: 'bold',
                }}
              >
                <FormattedMessage id="secure2u.clickHere" />
              </Button>
            </strong>
          </div>
          <CountdownTimer
            isStart={isShowTimer}
            onComplete={onTimerComplete}
            duration={RESEND_TAC_TIMER}
            title={formatMessage({ id: 'tac.timerTitle' })}
            secure2u
            backRetrySecure2u={backRetrySecure2u}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={7} container alignItems="center">
        <Grid item md={12} sm={12} xs={12} container alignItems="center">
          <Grid container xs={12} sm={12} md={12}>
            <Box
              style={{
                backgroundColor: '#FDEFC2',
                borderRadius: '4px',
                padding: window.innerHeight < 600 ? '10px' : '15px',
              }}
              className="box-tutorial-secure2u"
            >
              <Grid container xs={12} sm={4} md={4}>
                <Grid item xs={2} sm={2} md={2}>
                  <img className src={StepOne} alt="step-one" style={{ borderRadius: '8px', width: 35, height: 34 }} />
                </Grid>
                <Grid item xs={10} sm={10} md={10}>
                  <Grid item>
                    <Typography className="step-text">
                      <FormattedMessage id="secure2u.stepOne" />
                    </Typography>
                  </Grid>
                  <Grid item className="mt2" style={{ lineHeight: '1.2' }}>
                    <Box className=" step-text">
                      <Typography className="step-text">
                        <FormattedMessage id="secure2u.descriptionOne" />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={4} md={4} className="step-2">
                <Grid item xs={2} sm={2} md={2}>
                  <img className src={StepTwo} alt="step-one" style={{ borderRadius: '8px', marginRight: '10px', width: 35, height: 34 }} />
                </Grid>
                <Grid container direction="column" xs={10} sm={10} md={10}>
                  <Grid item>
                    <Typography className="step-text">
                      <FormattedMessage id="secure2u.stepTwo" />
                    </Typography>
                  </Grid>
                  <Grid item className="mt2" style={{ lineHeight: '1.2' }}>
                    <Box className=" step-text">
                      <Typography className="step-text">
                        <FormattedMessage id="secure2u.descriptionTwo" />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={4} md={4} className="step-2">
                <Grid item xs={2} sm={2} md={2}>
                  <img
                    className
                    src={StepThree}
                    alt="step-one"
                    style={{ borderRadius: '8px', marginRight: '10px', width: 35, height: 34 }}
                  />
                </Grid>
                <Grid container direction="column" xs={10} sm={10} md={10}>
                  <Grid item>
                    <Typography className="step-text">
                      <FormattedMessage id="secure2u.stepThree" />
                    </Typography>
                  </Grid>
                  <Grid item className="mt2" style={{ lineHeight: '1.2' }}>
                    <Box className=" step-text">
                      <Typography className="step-text">
                        <FormattedMessage id="secure2u.descriptionThree" />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Secure2uStepFinalSection.defaultProps = {
  isShowTimer: false,
  onTimerComplete: null,
  showPopup: false,
  backRetrySecure2u: false,
};

Secure2uStepFinalSection.propTypes = {
  isShowTimer: PropTypes.bool,
  onTimerComplete: PropTypes.func,
  showPopup: PropTypes.func,
  backRetrySecure2u: PropTypes.bool,
};

export default Secure2uStepFinalSection;
