import { actions } from 'middleware/actions/transfer';

const {
  SKN,
  RTGS,
  SWIFT,
  E_WALLET,
  INTERBANK,
  INTRABANK,
  RESET_TRANSFER,
  RESET_TRANSFER_CONFIRMATION,
  SENDER_ACCOUNT_RESPONSE,
  RECEIVER_ACCOUNT_RESPONSE,
  SELECTED_RECEIVER_ACCOUNT,
  OWN_ACCOUNT,
  VIRTUAL_ACCOUNT,
  CUSTOMER_DETAIL,
} = actions.PUT;

const initialState = {
  customerDetail: {},
  interbankDetail: {},
  interbankTac: {},
  interbankResult: {},
  interbankReceipt: {},
  interbankConfirmation: {},
  senderAccountResult: {},
  receiverAccountResult: {},
  ownTransferConfirmation: {},
  ownTransferTac: {},
  ownTransferResult: {},
  ownTransferReceipt: {},
  intrabankDetail: {},
  intrabankConfirmation: {},
  intrabankTac: {},
  intrabankResult: {},
  intrabankReceipt: {},
  sknTransferDetail: {},
  sknTransferConfirmation: {},
  sknTransferTac: {},
  sknTransferResult: {},
  sknTransferReceipt: {},
  rtgsDetail: {},
  rtgsConfirmation: {},
  rtgsTac: {},
  rtgsResult: {},
  rtgsReceipt: {},
  virtualAccountDetail: {},
  virtualAccountConfirmation: {},
  virtualAccountTac: {},
  virtualAccountResult: {},
  virtualAccountReceipt: {},
  swiftTac: {},
  swiftAccess: {},
  swiftBank: {},
  swiftReceipt: {},
  swiftResult: {},
  swiftLldDetail: {},
  swiftBankDetail: {},
  swiftConfirmation: {},
  eWalletDetail: {},
  eWalletConfirmation: {},
  eWalletTac: {},
  eWalletResult: {},
  eWalletReceipt: {},
};

const TransferReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_TRANSFER:
      return initialState;
    case RESET_TRANSFER_CONFIRMATION:
      return {
        ...state,
        interbankConfirmation: {},
        ownTransferConfirmation: {},
        intrabankConfirmation: {},
        sknTransferConfirmation: {},
        rtgsConfirmation: {},
        virtualAccountConfirmation: {},
        swiftConfirmation: {},
        eWalletConfirmation: {},
      };
    case CUSTOMER_DETAIL:
      return { ...state, customerDetail: action.res };
    case SENDER_ACCOUNT_RESPONSE:
      return { ...state, senderAccountResult: action.res };
    case RECEIVER_ACCOUNT_RESPONSE:
      return { ...state, receiverAccountResult: action.res };
    case SELECTED_RECEIVER_ACCOUNT:
      return { ...state, receiverAccountResult: { ...state.receiverAccountResult, selectedAccount: action.payload } };
    case OWN_ACCOUNT.TRANSFER_CONFIRMATION:
      return { ...state, ownTransferConfirmation: action.payload };
    case OWN_ACCOUNT.TAC_RESULT:
      return { ...state, ownTransferTac: action.payload };
    case OWN_ACCOUNT.TRANSFER_RESULT:
      return { ...state, ownTransferResult: action.payload };
    case OWN_ACCOUNT.RECEIPT:
      return { ...state, ownTransferReceipt: action.payload };
    case INTERBANK.TRANSFER_DETAIL:
      return { ...state, interbankDetail: action.payload.res };
    case INTERBANK.TRANSFER_CONFIRMATION:
      return { ...state, interbankConfirmation: action.res };
    case INTERBANK.TAC_RESULT:
      return { ...state, interbankTac: action.res };
    case INTERBANK.TRANSFER_RESULT:
      return { ...state, interbankResult: action.res };
    case INTERBANK.RECEIPT:
      return { ...state, interbankReceipt: action.res };
    case INTRABANK.TRANSFER_DETAIL:
      return { ...state, intrabankDetail: action.payload };
    case INTRABANK.TRANSFER_CONFIRMATION:
      return { ...state, intrabankConfirmation: action.payload };
    case INTRABANK.TAC_RESULT:
      return { ...state, intrabankTac: action.payload };
    case INTRABANK.TRANSFER_RESULT:
      return { ...state, intrabankResult: action.payload };
    case INTRABANK.RECEIPT:
      return { ...state, intrabankReceipt: action.payload };
    case SKN.TRANSFER_DETAIL:
      return { ...state, sknTransferDetail: action.payload };
    case SKN.TRANSFER_CONFIRMATION:
      return { ...state, sknTransferConfirmation: action.payload };
    case SKN.TAC_RESULT:
      return { ...state, sknTransferTac: action.payload };
    case SKN.TRANSFER_RESULT:
      return { ...state, sknTransferResult: action.payload };
    case SKN.RECEIPT:
      return { ...state, sknTransferReceipt: action.payload };
    case RTGS.TRANSFER_DETAIL:
      return { ...state, rtgsDetail: action.payload };
    case RTGS.TRANSFER_CONFIRMATION:
      return { ...state, rtgsConfirmation: action.payload };
    case RTGS.TAC_RESULT:
      return { ...state, rtgsTac: action.payload };
    case RTGS.TRANSFER_RESULT:
      return { ...state, rtgsResult: action.payload };
    case RTGS.RECEIPT:
      return { ...state, rtgsReceipt: action.payload };
    case VIRTUAL_ACCOUNT.TRANSFER_DETAIL:
      return { ...state, virtualAccountDetail: action.payload };
    case VIRTUAL_ACCOUNT.TRANSFER_CONFIRMATION:
      return { ...state, virtualAccountConfirmation: action.payload };
    case VIRTUAL_ACCOUNT.TAC_RESULT:
      return { ...state, virtualAccountTac: action.payload };
    case VIRTUAL_ACCOUNT.TRANSFER_RESULT:
      return { ...state, virtualAccountResult: action.payload };
    case VIRTUAL_ACCOUNT.RECEIPT:
      return { ...state, virtualAccountReceipt: action.payload };
    case SWIFT.ACCESS:
      return { ...state, swiftAccess: action.payload };
    case SWIFT.BANKS:
      return { ...state, swiftBank: action.payload };
    case SWIFT.BANK_DETAIL:
      return { ...state, swiftBankDetail: action.payload };
    case SWIFT.RECEIPT:
      return { ...state, swiftReceipt: action.payload };
    case SWIFT.TAC_RESULT:
      return { ...state, swiftTac: action.payload };
    case SWIFT.TRANSFER_RESULT:
      return { ...state, swiftResult: action.payload };
    case SWIFT.TRANSFER_CONFIRMATION:
      return { ...state, swiftConfirmation: action.payload };
    case SWIFT.LLD_DETAIL:
      return { ...state, swiftLldDetail: action.payload };
    case E_WALLET.TRANSFER_DETAIL:
      return { ...state, eWalletDetail: action.payload };
    case E_WALLET.TRANSFER_CONFIRMATION:
      return { ...state, eWalletConfirmation: action.payload };
    case E_WALLET.TRANSFER_TAC:
      return { ...state, eWalletTac: action.payload };
    case E_WALLET.TRANSFER_RESULT:
      return { ...state, eWalletResult: action.payload };
    case E_WALLET.TRANSFER_RECEIPT:
      return { ...state, eWalletReceipt: action.payload };
    default:
      return state;
  }
};

export default TransferReducer;
