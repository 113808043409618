import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';

import { memo } from 'utils/react';
import { get } from 'utils/lodash';
import { isObject } from 'utils/formatter';

import { AUTOCOMPLETE_PAPER_STYLE } from './style';

import Icon from './Icon';

import './style/index.scss';

const Autocomplete = ({
  value,
  variant,
  isDisablePortal,
  options,
  onChange,
  onInputChange,
  placeholder,
  noOptionsText,
  hasIcon,
  isMultiline,
  isTextCapitalized,
}) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const popupIcon = variant === 'dropdown' ? <Icon type="keybaordArrowDown" /> : null;

  // if option is an object, returns its {label}, else return the option as a string
  const getOptionLabel = option => (isObject(option) ? get(option, 'label', '') : option);

  const renderOption = option => <span className={isTextCapitalized ? `ttc` : `ttn`}>{getOptionLabel(option)}</span>;

  // to fix mismatch error when value is empty
  const selectedOption = !isEmpty(value) ? value : null;

  // fix scrolling issue by setting the overflow-x value
  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflowX = 'initial';
      return;
    }

    document.body.style.overflowX = 'hidden';
  }, [isPopupOpen]);

  return (
    <MaterialAutocomplete
      disablePortal={isDisablePortal} // autocomplete selection remain at bottom
      disableClearable
      onOpen={() => setPopupOpen(true)}
      onClose={() => setPopupOpen(false)}
      value={selectedOption}
      options={options}
      popupIcon={popupIcon}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, val) => option.id === val.id}
      renderOption={renderOption}
      onInputChange={onInputChange}
      onChange={(event, option) => onChange(option)}
      className={`common autocomplete ${variant}`}
      PaperComponent={({ children }) => (
        <Paper style={AUTOCOMPLETE_PAPER_STYLE} className="autocomplete-list-wrapper">
          {children}
        </Paper>
      )}
      renderInput={params => (
        <div className="wrapper">
          {hasIcon && <Icon isAssetIcon type="listRound" className="icon" />}
          <TextField
            {...params}
            className="relative"
            multiline={isMultiline}
            placeholder={placeholder}
            InputProps={{ ...params.InputProps }}
          />
        </div>
      )}
    />
  );
};

Autocomplete.defaultProps = {
  value: '',
  hasIcon: true,
  isMultiline: false,
  options: [],
  placeholder: '',
  noOptionsText: 'No option found',
  variant: 'normal',
  isDisablePortal: true,
  onInputChange: null,
  isTextCapitalized: true,
};

Autocomplete.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  hasIcon: PropTypes.bool,
  isDisablePortal: PropTypes.bool,
  isMultiline: PropTypes.bool,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string,
  isTextCapitalized: PropTypes.bool,
  onInputChange: PropTypes.func, // change due to programmatic. ie: hooks
  onChange: PropTypes.func.isRequired, // change due to user's selection
  variant: PropTypes.oneOf(['normal', 'dropdown']),
};

export default memo(Autocomplete);
