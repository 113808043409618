import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';

import parse from 'html-react-parser';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { BasicButton } from 'components/common/buttons';
import Icon from 'components/common/Icon';

const Secure2uStepRetrySection = ({ onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container sm={12} md={12} item>
      <Grid item xs={12} sm={4} md={5} container className="title" alignItems="center">
        <Typography className="tac--title">
          <FormattedMessage id="secure2u.questionRetry" />
          <FormattedMessage id="secure2u.questionRetry2" />
          <span>{parse(formatMessage({ id: 'secure2u.questionRetry3' }))}</span>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={7} container justify="flex-end" alignItems="center">
        <Grid item md={3} sm={3} xs={12}>
          <BasicButton fullWidth size="large" onClick={onClick}>
            <FormattedMessage id="secure2u.buttonRetry" />
          </BasicButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

Secure2uStepRetrySection.defaultProps = {
  onClick: null,
};

Secure2uStepRetrySection.propTypes = {
  onClick: PropTypes.func,
};

export default Secure2uStepRetrySection;
