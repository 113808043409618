export const login = {
  intro: 'Humanising Banking',
  footerLinks: {
    covid19: 'Bantuan Covid-19',
    branches: 'Cabang yang Beroperasi',
    openAccount: 'Buka Rekening',
    insureMe: 'Lindungi Saya',
    cards: 'Kartu Kredit Maybank',
    loan: 'Ajukan Pinjaman',
    returnToClassic: 'Kembali ke Klasik',
    whatsNew: 'Informasi Terbaru',
    promotions: 'Promosi',
  },
  resetCredential: 'Reset Credential',
  forgotLogin: 'Mengalami Kendala Saat Masuk?',
  accessMobile: 'Untuk Akses Mobile Banking',
  greeting: 'Halo',
  securePhrase: {
    label: 'Apakah frase keamanan Anda {phrase}',
  },
  captcha: {
    label: 'Apakah gambar keamanan anda benar?',
  },
  username: {
    placeholder: 'User Name',
    errorMessages: {
      invalid: 'User Name tidak sesuai',
      length: 'Masukkan User Name 6-20 karakter',
      specialChar: 'Username tidak bisa termasuk karakter spesial kecuali underscore bar',
    },
  },
  password: {
    placeholder: 'Password',
    errorMessages: {
      invalid: 'User Name/Password salah. Klik "Lupa Login" untuk atur ulang password Anda',
      length: 'Password antara 8 dan 20 characters',
    },
  },
  feedbackDrawer: {
    usernameUnlocked: 'Username anda telah dibuka!',
    successPasswordChange: 'Kata Sandi Anda telah diubah!',
    usernameSentToEmail: 'Informasi Username anda telah dikirimkan ke alamat email terdaftar anda!',
  },
  oldCriteriaAlert: {
    title: 'Notifikasi',
    description:
      'Anda menggunakan kriteria kata sandi terdahulu. Untuk keamanan transaksi Anda akan kami arahkan untuk mengganti dengan kriteria kata sandi baru',
  },
  renewPasswordAlert: {
    title: 'Perbarui Kata Sandi Anda',
  },
  successRenewPasswordAlert: {
    caption1: 'Selamat!',
    caption2: 'Perbaruan kata sandi berhasil',
  },
  forceChangePassword: {
    updatePassword: 'Perbarui Kata Sandi',
    subHeader: 'User ID Anda telah dibuka kembali dan Anda diharuskan untuk segera mengganti kata sandi.',
    currentPassword: 'Kata Sandi Baru',
    newPassword: 'Konfirmasi Kata Sandi Baru',
    placeholderCurrentPassword: 'Masukkan Kata Sandi Baru',
    placeholderNewPassword: 'Konfirmasi Kata Sandi Baru',
    errorMessage: {
      invalidTac: 'TAC Salah',
      invalidOldPassword: 'Anda tidak dapat menggunakan 5 kata sandi sebelumnya yang sama. Silakan coba kata sandi lain',
      userBlocked:
        'User ID Anda terblokir. Silakan melepas blokir melalui Cabang Maybank terdekat atau hubungi Call Center Maybank 1500611/+622178869811 (dari luar negeri).',
      maxTac: 'Batas kesalahan TAC maksimum telah tercapai.',
      invalidLockedUser: 'User ID Anda terblokir, Silakan melepas blokir melalui menu "Unlock User ID/TAC"',
    },
    tac: {
      title: 'Silakan mengkonfirmasi kata sandi baru Anda ',
      description: 'Untuk melanjutkan, silakan ajukan permintaan <b>TAC</b> Anda. ',
      descriptionFinal:
        'Pergantian password Anda berhasil dilakukan. Anda dapat melanjutkan Login menggunakan password yang telah Anda buat.',
    },
  },
  changePhoneNumber: {
    updatePhoneNumber: 'Pengkinian Nomor handphone',
    subHeader:
      'Jika nomor handphone Anda belum terdaftar, silakan kunjungi kantor cabang Maybank terdekat atau hubungi call center maybank untuk mendaftarkan nomor hanphone baru Anda',
    successDesc: 'Penggantian Nomor Handphone Anda berhasil dilakukan.',
    label: {
      prevNumber: 'Nomor Handphone Sebelumnya',
      newNumber: 'Nomor Handphone Baru',
    },
    placeholder: {
      prevNumber: 'Nomor Handphone Sebelumnya',
      newNumber: 'Nomor Handphone Baru',
    },
    modal: {
      title: 'Pemberitahuan',
      description: 'Apakah Anda yakin ingin menggunakan nomor baru ini?',
    },
    button: {
      next: 'Lanjutkan',
      yes: 'Ya',
      no: 'Tidak',
      login: 'Login',
    },
    error: {
      sameNumber: 'Nomor handphone lama tidak boleh sama dengan nomor handphone baru',
      numberNotSame: 'Nomor handphone lama tidak sesuai',
      errorUpdate: 'Penggantian nomor handphone Anda belum berhasil. Silakan coba kembali',
    },
  },
};
