import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';

import Icon from 'components/common/Icon';
import IconButton from 'components/common/buttons/IconButton';

import './style.scss';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

// Empty modal. It's up to application to fill up the content and control its open state
const BasicModal = ({
  children,
  className,
  id,
  footer,
  hasCloseButton,
  hasFooter,
  onClose,
  isOpen,
  title,
  fullWidth,
  fullScreen,
  maxWidth,
  disableBackdropClick,
  hideBackdrop,
  secure2u,
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby={id}
    open={isOpen}
    fullWidth={fullWidth}
    maxWidth={secure2u ? 'xl' : maxWidth}
    fullScreen={secure2u ? true : fullScreen}
    TransitionComponent={Transition}
    className={`modal modal-basic ${className}`}
    disableBackdropClick={disableBackdropClick}
    hideBackdrop={hideBackdrop}
    style={{
      paddingTop: secure2u && '20px',
      paddingBottom: secure2u && '20px',
    }}
  >
    <DialogTitle id={id} disableTypography>
      {title}
      {hasCloseButton && (
        <IconButton onClick={onClose} className="close-btn">
          <Icon isAssetIcon={false} type="close" />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    {hasFooter && <DialogActions style={{ margin: secure2u ? '-10px 0' : '' }}>{footer}</DialogActions>}
  </Dialog>
);

BasicModal.defaultProps = {
  id: '',
  className: '',
  title: '',
  footer: '',
  fullWidth: false,
  fullScreen: false,
  onClose: null,
  hasCloseButton: true,
  hasFooter: false,
  maxWidth: 'sm',
  children: null,
  disableBackdropClick: false.valueOf,
  hideBackdrop: false,
  secure2u: false,
};

BasicModal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  title: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  hasFooter: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs', false]),
  disableBackdropClick: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  secure2u: PropTypes.bool,
};

export default BasicModal;
