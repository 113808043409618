import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import {
  formatBalance,
  formatConfirmStopAroDetail,
  formatInitiateStopAroDetail,
  formatStopAroReceipt,
  formatStopAroTacDetail,
} from 'utils/dashboard';

import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';

import { dashboardEndpoint as endpoint } from 'providers/endpoints/dashboard';
import { transferEndpoint as tfEndpoint } from 'providers/endpoints/transfer';

import { KEYMAP, STOP_ARO_KEYMAP } from 'settings/constants/keymap';
import { SAVING_ACCOUNT } from 'settings/constants/common';
import {
  CC_DISPLAY_RESPONSE_CODE,
  ERROR_M9_RESPONSE_CODE,
  SUCCESS_RESPONSE_CODE,
  UNRESPONSIVE_HOST,
} from 'settings/constants/response-codes';

import { putErrorResponse } from 'middleware/actions/error';
import {
  INITIAL_ACCOUNT_BALANCES,
  WEALTH_BANCA_FUND,
  WEALTH_BOND_FUND,
  WEALTH_HISTORY_30_DAY,
  WEALTH_MUTUAL_FUND,
} from 'settings/constants/dashboard';
import {
  actions,
  putStopAroTac,
  putCasaAccount,
  putLoanAccounts,
  putResetStopAro,
  putWealthAccount,
  putConfirmStopAro,
  putStopAroReceipt,
  putInitiateStopAro,
  putLoanAccountDetail,
  putAllAccountBalance,
  putCrecitCardAccounts,
  putTimeDepositAccounts,
  putSavingAccountDetail,
  putCurrentAccountDetail,
  putWealthAccountSummary,
  putUnitTrustAccountDetail,
  putCasaTransactionHistory,
  putCreditCardAccountDetail,
  putTimeDepositAccountDetail,
  putTimeDepositProducts,
  putCreditCardTransactionHistory,
  putWealthAccountDetailAndHistory,
  putWealthAccountHistory,
  putDashboardResponseErrors,
  putDashboardResponseError,
  putCasaTransactionType,
  putCasaTransactionM2UReceipt,
  putTimeDepositInterestRate,
  putTimeDepositListAccounts,
  putTimeDepositValidate,
  putTimeDepositReqTAC,
  getTimeDepositDoOpenAccount,
  putTimeDepositDoOpenAccount,
} from '../actions/dashboard';

const {
  ALL_ACCOUNT_BALANCE,
  CASA_ACCOUNT_DETAIL,
  LOAN_ACCOUNT_DETAIL,
  WEALTH_ACCOUNT_SUMMARY,
  WEALTH_ACCOUNT_DETAIL_AND_HISTORY,
  WEALTH_ACCOUNT_HISTORY,
  UNIT_TRUST_ACCOUNT_DETAIL,
  CREDIT_CARD_ACCOUNT_DETAIL,
  TIME_DEPOSIT_ACCOUNT_DETAIL,
  CASA_ACCOUNT_BALANCE,
  CREDIT_CARD_ACCOUNT_BALANCE,
  LOAN_ACCOUNT_BALANCE,
  TIME_DEPOSIT_ACCOUNT_BALANCE,
  WEALTH_ACCOUNT_BALANCE,
  CASA_TRANSACTION_TYPE,
  CASA_TRANSACTION_HISTORY,
  CREDIT_CARD_TRANSACTION_HISTORY,
  TIME_DEPOSIT_INITIATE_STOP_ARO,
  TIME_DEPOSIT_STOP_ARO_TAC,
  TIME_DEPOSIT_CONFIRM_STOP_ARO,
  TIME_DEPOSIT_STOP_ARO_RECEIPT,
  TIME_DEPOSIT_PRODUCTS,
  TIME_DEPOSIT_INTEREST_RATE,
  TIME_DEPOSIT_LIST_ACCOUNTS,
  TIME_DEPOSIT_VALIDATE,
  TIME_DEPOSIT_REQ_TAC,
  TIME_DEPOSIT_DO_OPEN_ACCOUNT,
  CASA_TRANSACTION_M2U_RECEIPT,
} = actions.GET;

const [casaKey, tdKey, ccKey, loanKey, wealthKey] = INITIAL_ACCOUNT_BALANCES.map(account => account.value);

const getClientIP = () => remoteIpInstance.get();

// .catch is to prevent saga handler error
const getAllAccountBalance = ({ body }) => Instance.post(endpoint.get.allAccountBalance, body).catch(e => null);
const getTotalWealth = ({ body }) => Instance.post(endpoint.get.totalWealth, body).catch(e => null);

const getCasaAccountBalance = ({ body }) => Instance.post(endpoint.get.casaAccountBalance, body);
const getLoanAccountBalance = ({ body }) => Instance.post(endpoint.get.loanAccountBalance, body);
const getCreditCardAccountBalance = ({ body }) => Instance.post(endpoint.get.creditCardAccountBalance, body);
const getTimeDepositAccountBalance = ({ body }) => Instance.post(endpoint.get.timeDepositAccountBalance, body);

const getLoanAccountDetail = ({ body }) => Instance.post(endpoint.get.loanAccountDetail, body);
const getCasaAccountDetail = ({ body }) => Instance.post(endpoint.get.casaAccountDetail, body);
const getUnitTrustAccountDetail = ({ body }) => Instance.post(endpoint.get.unitTrustDetail, body);
const getCreditCardAccountDetail = ({ body }) => Instance.post(endpoint.get.creditCardDetail, body);
const getTimeDepositAccountDetail = ({ body }) => Instance.post(endpoint.get.timeDepositAccountDetail, body);
const getTimeDepositProduct = ({ body }) => Instance.post(endpoint.get.products, body);
const getTimeDepositInterestRate = ({ body }) => Instance.post(endpoint.get.interestRate, body);
const getTimeDepositListAccount = ({ body }) => Instance.post(tfEndpoint.get.senderAccount, body);
const getTimeDepositValidate = ({ body }) => Instance.post(endpoint.get.validateTD, body);
const getTimeDepositRequestTAC = ({ body }) => Instance.post(endpoint.get.requestTDTAC, body);
const getTimeDepositOpenAccount = ({ body }) => Instance.post(endpoint.get.doOpenAccount, body);

const getCasaTransactionType = ({ body }) => Instance.post(endpoint.get.casaTransactionType, body);
const getCasaTransactionM2UReceipt = ({ body }) => Instance.post(endpoint.get.casaTransactionM2UReceipt, body);

const getSavingTransactionHistory = ({ body }) => Instance.post(endpoint.get.savingTransactionHistory, body);
const getCurrentTransactionHistory = ({ body }) => Instance.post(endpoint.get.currentTransactionHistory, body);
const getCreditCardTransactionHistory = ({ body }) => Instance.post(endpoint.get.creditCardTransactionHistory, body);

const getInitiateStopAro = ({ body }) => Instance.post(endpoint.get.initiateStopAro, body);
const getRequestTac = ({ body }) => Instance.post(endpoint.get.requestTac, body);
const getConfirmStopAro = ({ body }) => Instance.post(endpoint.get.confirmStopAro, body);
const getStopAroReceipt = ({ body }) => Instance.post(endpoint.get.stopAroReceipt, body);

// Wealth
const getWealthAccountBalance = ({ body }) => Instance.post(endpoint.get.wealthAccountBalance, body);
const getMutualFundSummary = ({ body }) => Instance.post(endpoint.get.mutualFundSummary, body);
const getMutualFundDetail = ({ body }) => Instance.post(endpoint.get.mutualFundDetail, body);
const getMutualFundHistory = ({ body }) => Instance.post(endpoint.get.mutualFundHistory, body);
const getBondSummary = ({ body }) => Instance.post(endpoint.get.bondSummary, body);
const getBondDetail = ({ body }) => Instance.post(endpoint.get.bondDetail, body);
const getBondHistory = ({ body }) => Instance.post(endpoint.get.bondHistory, body);
const getBancaSummary = ({ body }) => Instance.post(endpoint.get.bancaSummary, body);
const getBancaDetail = ({ body }) => Instance.post(endpoint.get.bancaDetail, body);

const getLocale = state => state.LanguageReducer.locale;

function* getAllAccountBalanceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const [accountBalance, totalWealth] = yield all([call(getAllAccountBalance, { body }), call(getTotalWealth, { body })]);

    const accountData = get(accountBalance, 'data', {});
    const wealthData = get(totalWealth, 'data', {});

    const accountResponseCode = get(accountData, 'responseCode', '');
    const wealthResponseCode = get(wealthData, 'responseCode', '');

    if (
      accountResponseCode === SUCCESS_RESPONSE_CODE ||
      accountResponseCode === ERROR_M9_RESPONSE_CODE ||
      wealthResponseCode === SUCCESS_RESPONSE_CODE ||
      accountResponseCode === ERROR_M9_RESPONSE_CODE
    ) {
      const res = formatBalance(accountData, wealthData);
      yield put(putAllAccountBalance({ isSuccess: true, res }));
    }
  } catch (error) {
    // comment to avoid frequent popup error drawer
    // yield put(putErrorResponse(error));
  }
}

function* getCasaAccountBalanceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getCasaAccountBalance, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = {
      savingAccounts: get(data, 'savingList', []),
      currentAccounts: get(data, 'currentList', []),
      exchangeRate: get(data, 'exchangeRate', []),
    };

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putCasaAccount({ errorCode: '', isSuccess: true, res })),
        put(putDashboardResponseErrors({ errorCode: '', key: casaKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: casaKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCreditCardAccountBalanceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getCreditCardAccountBalance, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'creditCardList', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === CC_DISPLAY_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([put(putCrecitCardAccounts({ isSuccess: true, res })), put(putDashboardResponseErrors({ errorCode: '', key: ccKey }))]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: ccKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getLoanAccountBalanceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getLoanAccountBalance, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'loanList', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putLoanAccounts({ errorCode: '', isSuccess: true, res })),
        put(putDashboardResponseErrors({ errorCode: '', key: loanKey })),
      ]);
    } else {
      yield put(putDashboardResponseError({ errorCode: responseCode, key: loanKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositAccountBalanceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getTimeDepositAccountBalance, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const exchangeRate = get(data, 'exchangeRate', []);
    const res = get(data, 'timeDepositList', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositAccounts({ errorCode: '', isSuccess: true, res, exchangeRate })),
        put(putDashboardResponseErrors({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: tdKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositProductsSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getTimeDepositProduct, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'responseData', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositProducts({ errorCode: '', isSuccess: true, res })),
        put(putDashboardResponseError({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putErrorResponse(data));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositInterestRateSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getTimeDepositInterestRate, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'responseData', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositInterestRate({ errorCode: '', isSuccess: true, res })),
        put(putDashboardResponseError({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putErrorResponse(data));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositListAccountsSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getTimeDepositListAccount, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'fromAccountList', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositListAccounts({ errorCode: '', isSuccess: true, res })),
        put(putDashboardResponseError({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putErrorResponse(data));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositValidateSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getTimeDepositValidate, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const resp = get(data, 'responseData');
    const res = get(resp, 'rate', '');

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositValidate({ errorCode: '', isSuccess: true, res })),
        put(putDashboardResponseError({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putErrorResponse(data));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositReqTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIp: ip, ...payload };

    const { data } = yield call(getTimeDepositRequestTAC, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const resSentTacDate = get(data, 'tacSentDateTime', '');
    const resSendSms = get(data, 'sendSmsSuccess', '');

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositReqTAC({ errorCode: '', isSuccess: true, sendSmsSuccess: resSendSms, tacSentDatetime: resSentTacDate })),
        put(putDashboardResponseError({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putErrorResponse(data));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositOpenAccountSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getTimeDepositOpenAccount, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'responseData', {});

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositDoOpenAccount({ errorCode: '', isSuccess: true, res, doneHit: true })),
        put(putDashboardResponseError({ errorCode: '', key: tdKey })),
      ]);
    } else {
      yield put(putTimeDepositDoOpenAccount({ isSuccess: false, responseCode, doneHit: true }));
    }
  } catch (error) {
    yield put(putErrorResponse({}));
  }
}

function* getWealthAccountBalanceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getWealthAccountBalance, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'responseData.wealthList', []);

    if (responseCode === SUCCESS_RESPONSE_CODE || responseCode === ERROR_M9_RESPONSE_CODE) {
      yield all([put(putWealthAccount({ isSuccess: true, res })), put(putDashboardResponseErrors({ errorCode: '', key: wealthKey }))]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: wealthKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getWealthAccountSummarySaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const wealthType = get(payload, 'wealthType');

    const body = { locale, ...payload };

    const handler = {
      [WEALTH_MUTUAL_FUND]: getMutualFundSummary,
      [WEALTH_BOND_FUND]: getBondSummary,
      [WEALTH_BANCA_FUND]: getBancaSummary,
    };

    const { data } = yield call(handler[wealthType], { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'responseData', {});

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putWealthAccountSummary({ isSuccess: true, res })),
        put(putDashboardResponseErrors({ errorCode: '', key: wealthKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: wealthKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getWealthAccountDetailSaga({ payload, accountType }) {
  try {
    const locale = yield select(getLocale);

    const body = { locale, ...payload };
    // default history should be 30days
    const optionListing = WEALTH_HISTORY_30_DAY;

    const handler = {
      [WEALTH_MUTUAL_FUND]: () => [call(getMutualFundDetail, { body }), call(getMutualFundHistory, { body: { ...body, optionListing } })],
      [WEALTH_BOND_FUND]: () => [call(getBondDetail, { body }), call(getBondHistory, { body: { ...body, optionListing } })],
      [WEALTH_BANCA_FUND]: () => [call(getBancaDetail, { body })],
    };

    const [dirtyDetail, dirtyHistory] = yield all(handler[accountType]());

    const detail = get(dirtyDetail, 'data', {});
    const history = get(dirtyHistory, 'data', {});

    const responseCodeDetail = get(detail, 'responseCode', UNRESPONSIVE_HOST);
    const responseCodeHistory = get(history, 'responseCode', UNRESPONSIVE_HOST);

    const detailResponse = get(detail, 'responseData', {});
    const historyResponse = get(history, 'responseData.historyList', []);

    // BANCA DOESNT HAVE history api
    if (accountType === WEALTH_BANCA_FUND && responseCodeDetail === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putWealthAccountDetailAndHistory({ detailResponse, historyResponse, isSuccess: true })),
        put(putDashboardResponseErrors({ errorCode: '', key: wealthKey })),
      ]);
    }

    if (responseCodeDetail === SUCCESS_RESPONSE_CODE && responseCodeHistory === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putWealthAccountDetailAndHistory({ detailResponse, historyResponse, isSuccess: true })),
        put(putDashboardResponseErrors({ errorCode: '', key: wealthKey })),
      ]);
    }

    if (responseCodeDetail !== SUCCESS_RESPONSE_CODE) {
      yield put(putDashboardResponseErrors({ errorCode: responseCodeHistory, key: wealthKey }));
    }
    // BANCA DOESNT HAVE history api
    if (accountType !== WEALTH_BANCA_FUND && responseCodeHistory !== SUCCESS_RESPONSE_CODE) {
      yield put(putDashboardResponseErrors({ errorCode: responseCodeHistory, key: wealthKey }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getWealthAccountHistorySaga({ payload, accountType }) {
  try {
    const locale = yield select(getLocale);

    const body = { locale, ...payload };

    const handler = {
      [WEALTH_MUTUAL_FUND]: () => call(getMutualFundHistory, { body: { ...body } }),
      [WEALTH_BOND_FUND]: () => call(getBondHistory, { body: { ...body } }),
    };

    const { data } = yield handler[accountType]();

    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const res = get(data, 'responseData.historyList', []);

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putWealthAccountHistory({ isSuccess: true, res })),
        put(putDashboardResponseErrors({ errorCode: '', key: wealthKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: wealthKey, res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTimeDepositAccountDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getTimeDepositAccountDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putTimeDepositAccountDetail({ errorCode: '', isSuccess: true, res: data })),
        put(putDashboardResponseErrors({ errorCode: '', key: wealthKey })),
      ]);
    } else {
      yield put([putDashboardResponseErrors({ errorCode: responseCode, key: tdKey, res: data })]);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCreditCardAccountDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    // Seperate cc transaction history and cc detail payload
    const { history, creditCardNumber, accountNumber, ...restPayload } = payload;
    const creditCardDetailBody = { remoteIPAddress: ip, locale, creditCardNumber, ...restPayload };
    const transactionHistoryBody = { remoteIPAddress: ip, locale, accountNumber, history, ...restPayload };

    const [details, histories] = yield all([
      call(getCreditCardAccountDetail, { body: creditCardDetailBody }),
      call(getCreditCardTransactionHistory, { body: transactionHistoryBody }),
    ]);

    const creditCardDetails = get(details, 'data', {});
    const transactionHistory = get(histories, 'data', {});

    const detailsResponseCode = get(creditCardDetails, 'responseCode', UNRESPONSIVE_HOST);
    const historyResponseCode = get(transactionHistory, 'responseCode', '');

    if (detailsResponseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putCreditCardAccountDetail({ isSuccess: true, res: creditCardDetails })),
        put(putDashboardResponseErrors({ errorCode: '', key: ccKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: historyResponseCode, key: ccKey, res: creditCardDetails }));
    }

    if (historyResponseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putCreditCardTransactionHistory({ isSuccess: true, res: transactionHistory })),
        put(putDashboardResponseErrors({ errorCode: '', key: ccKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: historyResponseCode, key: ccKey, res: creditCardDetails }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getLoanAccountDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getLoanAccountDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putLoanAccountDetail({ errorCode: '', isSuccess: true, res: data })),
        put(putDashboardResponseErrors({ errorCode: '', key: loanKey })),
      ]);
    } else {
      yield put(putLoanAccountDetail({ errorCode: responseCode, isSuccess: false, res: data }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getUnitTrustAccountDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, KEYMAP);
    const { data } = yield call(getUnitTrustAccountDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putUnitTrustAccountDetail({ isSuccess: true, res: data })),
        put(putDashboardResponseErrors({ errorCode: '', key: wealthKey })),
      ]);
    } else {
      yield put([[putDashboardResponseErrors]]({ errorCode: responseCode, key: loanKey, data }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

// NOTE: Call Saving/Current account by passing different props
function* getCasaAccountDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getCasaAccountDetail, { body });
    const accountType = get(body, 'accountType');
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
    const isSavingAccount = accountType === SAVING_ACCOUNT;

    if (isSuccess) {
      if (isSavingAccount) {
        yield put(putSavingAccountDetail({ isSuccess: true, res: data }));
      }
      yield all([
        put(putCurrentAccountDetail({ isSuccess: true, res: data })),
        put(putDashboardResponseErrors({ errorCode: '', key: casaKey })),
      ]);
    } else {
      yield put(putDashboardResponseErrors({ errorCode: responseCode, key: casaKey, data }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCasaTransactionTypeSaga({ payload }) {
  try {
    const { ip } = yield call(getClientIP);
    const locale = yield select(getLocale);
    const body = { locale, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getCasaTransactionType, { body });

    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putCasaTransactionType({ isSuccess: true, res: data })),
        put(putDashboardResponseErrors({ errorCode: '', key: casaKey })),
      ]);
    } else {
      yield all([
        put(putCasaTransactionType({ isSuccess: true, res: [] })),
        put(putDashboardResponseErrors({ errorCode: responseCode, key: casaKey, data })),
      ]);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCasaTransactionHistorySaga({ payload }) {
  try {
    const { ip } = yield call(getClientIP);
    const locale = yield select(getLocale);
    const initialBody = { locale, remoteIPAddress: ip, ...payload };

    const { accountType, ...body } = initialBody;
    const isSavingAccount = accountType === SAVING_ACCOUNT;

    const { data } = isSavingAccount
      ? yield call(getSavingTransactionHistory, { body })
      : yield call(getCurrentTransactionHistory, { body });

    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield all([
        put(putCasaTransactionHistory({ isSuccess: true, res: data })),
        put(putDashboardResponseErrors({ errorCode: '', key: casaKey })),
      ]);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCreditCardTxHistory({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { ...payload, remoteIPAddress: ip, userAgent, locale };
    const { data } = yield call(getCreditCardTransactionHistory, { body });

    const responseCode = get(data, 'responseCode', '');

    yield put(putCreditCardTransactionHistory({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, res: data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getInitiateStopAroSaga({ payload }) {
  try {
    yield put(putResetStopAro());
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, STOP_ARO_KEYMAP);
    const { data } = yield call(getInitiateStopAro, { body });

    const res = formatInitiateStopAroDetail(data);
    yield put(putInitiateStopAro(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getStopAroTacSaga({ payload }) {
  try {
    yield put(putStopAroTac({ isTacRequested: false }));
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { remoteIPAddress: ip, userAgent, locale, ...payload };
    const { data } = yield call(getRequestTac, { body });

    const res = formatStopAroTacDetail(data);
    yield put(putStopAroTac(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmStopAroSaga({ payload }) {
  try {
    yield put(putConfirmStopAro({ isConfirmed: false }));
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const initialBody = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const body = formatKeys(initialBody, STOP_ARO_KEYMAP);
    const { data } = yield call(getConfirmStopAro, { body });

    const res = formatConfirmStopAroDetail(data);
    yield put(putConfirmStopAro(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getStopAroReceiptSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, STOP_ARO_KEYMAP);
    const { data } = yield call(getStopAroReceipt, { body });

    const res = formatStopAroReceipt(data);
    yield put(putStopAroReceipt(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCasaTransactionM2UReceiptSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getCasaTransactionM2UReceipt, { body });

    const res = formatStopAroReceipt(data);
    yield put(putCasaTransactionM2UReceipt(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Dashboard() {
  yield all([
    takeLatest(ALL_ACCOUNT_BALANCE, getAllAccountBalanceSaga),
    takeLatest(LOAN_ACCOUNT_DETAIL, getLoanAccountDetailSaga),
    takeLatest(CASA_ACCOUNT_DETAIL, getCasaAccountDetailSaga),
    takeLatest(CASA_ACCOUNT_BALANCE, getCasaAccountBalanceSaga),
    takeLatest(LOAN_ACCOUNT_BALANCE, getLoanAccountBalanceSaga),
    takeLatest(WEALTH_ACCOUNT_BALANCE, getWealthAccountBalanceSaga),
    takeLatest(WEALTH_ACCOUNT_SUMMARY, getWealthAccountSummarySaga),
    takeLatest(WEALTH_ACCOUNT_DETAIL_AND_HISTORY, getWealthAccountDetailSaga),
    takeLatest(WEALTH_ACCOUNT_HISTORY, getWealthAccountHistorySaga),
    takeLatest(UNIT_TRUST_ACCOUNT_DETAIL, getUnitTrustAccountDetailSaga),
    takeLatest(CREDIT_CARD_ACCOUNT_DETAIL, getCreditCardAccountDetailSaga),
    takeLatest(CREDIT_CARD_ACCOUNT_BALANCE, getCreditCardAccountBalanceSaga),
    takeLatest(TIME_DEPOSIT_ACCOUNT_DETAIL, getTimeDepositAccountDetailSaga),
    takeLatest(TIME_DEPOSIT_ACCOUNT_BALANCE, getTimeDepositAccountBalanceSaga),
    takeLatest(CASA_TRANSACTION_TYPE, getCasaTransactionTypeSaga),
    takeLatest(CASA_TRANSACTION_HISTORY, getCasaTransactionHistorySaga),
    takeLatest(CREDIT_CARD_TRANSACTION_HISTORY, getCreditCardTxHistory),
    takeLatest(TIME_DEPOSIT_INITIATE_STOP_ARO, getInitiateStopAroSaga),
    takeLatest(TIME_DEPOSIT_STOP_ARO_TAC, getStopAroTacSaga),
    takeLatest(TIME_DEPOSIT_CONFIRM_STOP_ARO, getConfirmStopAroSaga),
    takeLatest(TIME_DEPOSIT_STOP_ARO_RECEIPT, getStopAroReceiptSaga),
    takeLatest(TIME_DEPOSIT_PRODUCTS, getTimeDepositProductsSaga),
    takeLatest(TIME_DEPOSIT_INTEREST_RATE, getTimeDepositInterestRateSaga),
    takeLatest(TIME_DEPOSIT_LIST_ACCOUNTS, getTimeDepositListAccountsSaga),
    takeLatest(TIME_DEPOSIT_VALIDATE, getTimeDepositValidateSaga),
    takeLatest(TIME_DEPOSIT_REQ_TAC, getTimeDepositReqTACSaga),
    takeLatest(TIME_DEPOSIT_DO_OPEN_ACCOUNT, getTimeDepositOpenAccountSaga),
    takeLatest(CASA_TRANSACTION_M2U_RECEIPT, getCasaTransactionM2UReceiptSaga),
  ]);
}
