import React, { Suspense, lazy, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { Icon, Loader } from 'components/common';
import Coachmark from 'components/coachmark';

import { postCoachmark } from 'middleware/actions/coachmark';

import { useWindowSize } from 'utils/transaction';
import { get } from 'utils/lodash';

import { COACHMARK_CHECKED, COACHMARK_UNCHECKED, DEFAULT_INT_ZERO, INT_FOUR, INT_ONE, TOTAL_COACHMARK } from 'settings/constants/common';

import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import history from './history';

import { routes } from './routes';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { IconButton } from '../components/common/buttons';
import { BasicModal } from '../components/common/modals';

const LoginPage = lazy(() => import('container/login'));
const LandingPage = lazy(() => import('pages/Landing'));
const LogoutPage = lazy(() => import('container/logout'));
const SettingsPage = lazy(() => import('pages/Settings'));
const InboxPage = lazy(() => import('pages/Inbox'));
const DashboardPage = lazy(() => import('pages/Dashboard'));
const TransactionPage = lazy(() => import('pages/Transaction'));
const RegisterPage = lazy(() => import('pages/Registration'));
const DigitalWealthPage = lazy(() => import('pages/DigitalWealth'));

const { login, selfUnlock, digitalWealth, dashboard, registration, logout, sessionExpired, transaction, settings, inboxs } = routes;

export const MainRoute = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isCoachmarkActionOpen, setCoachmarkActionOpen] = useState(false);
  const [isCoachmarkOpen, setCoachmarkOpen] = useState(false);
  const [hasRemember, setHasRemember] = useState(false);
  const [coachmarkIndex, setCoachmarkIndex] = useState(1);
  const [coachmarkImage, setCoachmarkImage] = useState(null);
  const [size, setSize] = useState([0, 0]);
  const [language, setLanguage] = useState('');
  const [coachmarkLoading, setCoachmarkLoading] = useState(true);
  const [paramActionSec2u, setParamActionSec2u] = useState(false);
  const [isSecure2u, setIsSecure2u] = useState(false);
  const [isMobileSecure2u, setIsMobileSecure2u] = useState(false);
  let type;

  const { coachmarkChecked, coachmarkSubmitted, isSuccess, customerProfile, activationSec2u } = useSelector(
    state => ({
      coachmarkChecked: state.CoachmarkReducer.coachmarkChecked,
      coachmarkSubmitted: state.CoachmarkReducer.coachmarkSubmitted,
      isSuccess: state.CoachmarkReducer.isSuccess,
      customerProfile: state.AuthReducer.customerProfile,
      activationSec2u: state.Sec2uReducer.activationSec2u,
    }),
    shallowEqual
  );

  const widthWindows = useWindowSize(size, setSize);

  const responsiveCoachmark = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth > screenHeight) {
      if (screenWidth > theme.breakpoints.width('md')) {
        type = 'website';
      } else {
        type = 'mobile-landscape';
      }
    } else if (screenWidth < screenHeight) {
      if (screenWidth < theme.breakpoints.width('sm')) {
        type = 'mobile';
      } else {
        type = 'tablet';
      }
    }
  };

  const loadCoachmarkImage = () => {
    setCoachmarkLoading(true);
    responsiveCoachmark();

    if (paramActionSec2u) setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}sec2u${language}/${type}/${coachmarkIndex}.png`);
    if (!paramActionSec2u) setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}coach${language}/${type}/${coachmarkIndex}.jpg`);
  };
  const openCoachmark = () => {
    setCoachmarkActionOpen(false);
    loadCoachmarkImage(coachmarkIndex);
    setCoachmarkOpen(true);
  };
  const handleCoachmarkPrev = () => {
    setCoachmarkIndex(index => index - 1);
    setCoachmarkLoading(true);
  };
  const handleCoachmarkNext = () => {
    if ((paramActionSec2u && coachmarkIndex === INT_FOUR) || coachmarkIndex === TOTAL_COACHMARK) {
      setCoachmarkOpen(false);
      setCoachmarkIndex(INT_ONE);
      return;
    }

    setCoachmarkIndex(index => index + 1);
    setCoachmarkLoading(true);
  };

  const localStorageLanguage = isEmpty(localStorage.getItem('language')) ? 'id' : localStorage.getItem('language').slice(1, -1);

  const handleTutorial = (actionSecure2u = false, secure2u = false, mobileSecure2u = false) => {
    const isActionSecure2u =
      typeof actionSecure2u === 'object' && actionSecure2u !== null && actionSecure2u.nativeEvent ? false : actionSecure2u;

    const isSecure2uParams = typeof secure2u === 'object' && secure2u !== null && secure2u.nativeEvent ? false : secure2u;
    const isMobileSecure2uParams =
      typeof mobileSecure2u === 'object' && mobileSecure2u !== null && mobileSecure2u.nativeEvent ? false : mobileSecure2u;

    if (isActionSecure2u) setParamActionSec2u(true);
    if (!isActionSecure2u) setParamActionSec2u(false);

    if (isSecure2uParams) setIsSecure2u(true);
    if (!isSecure2uParams) setIsSecure2u(false);

    if (isMobileSecure2uParams) setIsMobileSecure2u(true);
    if (!isMobileSecure2uParams) setIsMobileSecure2u(false);

    setCoachmarkIndex(INT_ONE);
    return isMobileSecure2uParams === false ? setCoachmarkOpen(true) : setIsMobileSecure2u(true);
  };
  const handleCloseCoachmark = () => {
    setCoachmarkOpen(false);
    setParamActionSec2u(false);

    if (navigator.onLine) {
      if (!coachmarkLoading) setIsSecure2u(false);
      setCoachmarkIndex(INT_ONE);

      if (!isEmpty(activationSec2u)) {
        Object.getOwnPropertyNames(activationSec2u).forEach(key => {
          delete activationSec2u[key];
        });
      }
    }
  };

  useEffect(() => {
    setParamActionSec2u(false);
  }, []);

  useEffect(() => {
    loadCoachmarkImage(coachmarkIndex);
  }, [coachmarkIndex]);

  useLayoutEffect(() => {
    loadCoachmarkImage();
  }, [window.innerWidth, widthWindows[0]]);

  useEffect(() => {
    if (isSuccess) {
      if (coachmarkSubmitted === COACHMARK_CHECKED) {
        if (coachmarkChecked === COACHMARK_CHECKED || coachmarkChecked?.length === DEFAULT_INT_ZERO) {
          setCoachmarkActionOpen(true);
        }
      }
      if (coachmarkSubmitted?.length === DEFAULT_INT_ZERO) {
        setCoachmarkActionOpen(true);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    setLanguage(get(customerProfile, 'language', ''));
  }, [customerProfile]);

  useEffect(() => {
    loadCoachmarkImage();
  }, [language]);

  useEffect(() => {
    responsiveCoachmark();

    if (!paramActionSec2u) setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}coach${language}/${type}/${coachmarkIndex}.jpg`);
    if (paramActionSec2u) setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}sec2u${language}/${type}/${coachmarkIndex}.png`);
  }, [paramActionSec2u]);

  useEffect(() => {
    responsiveCoachmark();

    if (!isSecure2u) setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}coach${language}/${type}/${coachmarkIndex}.jpg`);
    if (isSecure2u)
      setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}sec2u${isEmpty(language) ? localStorageLanguage : language}/${type}/trxOne.png`);
  }, [isSecure2u]);

  useMemo(() => {
    if (hasRemember && !isCoachmarkActionOpen) {
      if (isCoachmarkOpen) {
        dispatch(postCoachmark(COACHMARK_CHECKED));
        return;
      }
      dispatch(postCoachmark(COACHMARK_UNCHECKED));
    }
  }, [hasRemember, isCoachmarkActionOpen]);

  const CoachmarkComponent = (
    <>
      <Coachmark
        loading={coachmarkLoading}
        isCoachmarkOpen={isCoachmarkOpen}
        isCoachmarkActionOpen={isCoachmarkActionOpen}
        hasRemember={hasRemember}
        coachmarkIndex={coachmarkIndex}
        coachmarkImage={coachmarkImage}
        setLoading={setCoachmarkLoading}
        setCoachmarkActionOpen={setCoachmarkActionOpen}
        handleCoachmarkClose={handleCloseCoachmark}
        setHasRemember={setHasRemember}
        openCoachmark={openCoachmark}
        handleCoachmarkPrev={handleCoachmarkPrev}
        handleCoachmarkNext={handleCoachmarkNext}
        activeSecure2u={paramActionSec2u}
        secure2u={isSecure2u}
      />
      <BasicModal
        className="button-modal-mobil-secure2u"
        isOpen={isMobileSecure2u}
        disableBackdropClick
        hideBackdrop
        hasCloseButton={false}
        fullWidth
      >
        <IconButton
          onClick={() => {
            setCoachmarkOpen(true);
            setIsMobileSecure2u(false);
          }}
        >
          <Grid container direction="row" justify="space-between">
            <Grid item style={{ marginTop: '-4px' }}>
              <strong style={{ fontSize: 14, color: 'black' }}>
                <FormattedMessage id="secure2u.guideConfirmation" />
              </strong>
            </Grid>
            <Grid item style={{ marginTop: '2px', color: 'black' }}>
              <Icon type="chevronNext" />
            </Grid>
          </Grid>
        </IconButton>
      </BasicModal>
    </>
  );

  return (
    <Suspense fallback={<Loader isOpen />}>
      <Router history={history}>
        <Switch>
          <PublicRoute
            exact
            path="/"
            component={LandingPage}
            coachmarkComponent={CoachmarkComponent}
            handleTutorial={handleTutorial}
            handleCloseCoachmark={handleCloseCoachmark}
          />
          <PublicRoute
            component={LoginPage}
            handleTutorial={handleTutorial}
            handleCloseCoachmark={handleCloseCoachmark}
            coachmarkComponent={CoachmarkComponent}
            path={[login, selfUnlock]}
          />
          <PublicRoute component={RegisterPage} path={registration.default} />
          <PublicRoute exact component={LogoutPage} path={[logout, sessionExpired]} />

          <PrivateRoute
            exact
            component={DigitalWealthPage}
            handleTutorial={handleTutorial}
            handleCloseCoachmark={handleCloseCoachmark}
            coachmarkComponent={CoachmarkComponent}
            path={[digitalWealth.path, digitalWealth.goalDetail, digitalWealth.financialGoal]}
          />
          <PrivateRoute
            exact
            component={DashboardPage}
            handleTutorial={handleTutorial}
            handleCloseCoachmark={handleCloseCoachmark}
            coachmarkComponent={CoachmarkComponent}
            path={[dashboard.default, dashboard.details, dashboard.path, dashboard.summary, dashboard.apply]}
          />
          <PrivateRoute
            component={TransactionPage}
            handleTutorial={handleTutorial}
            handleCloseCoachmark={handleCloseCoachmark}
            coachmarkComponent={CoachmarkComponent}
            path={[transaction.default, transaction.path]}
            exact
          />
          <PrivateRoute
            component={SettingsPage}
            handleTutorial={handleTutorial}
            handleCloseCoachmark={handleCloseCoachmark}
            coachmarkComponent={CoachmarkComponent}
            path={[settings.path, settings.default]}
            exact
          />
          {/* <PrivateRoute
            component={InboxPage}
            handleTutorial={handleTutorial}
            coachmarkComponent={CoachmarkComponent}
            path={[inboxs.path, inboxs.default]}
            exact
          /> */}

          {/* if no path is found handling */}
          <PublicRoute component={LoginPage} />
        </Switch>
      </Router>
    </Suspense>
  );
};
