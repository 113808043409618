import PropTypes from 'prop-types';
import { useEffect, useReducer, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Drawer, Grid } from '@material-ui/core';

import { isValidTac } from 'utils/validation';

import { MOBILE_SCREEN_SIZE_PX, SIDEBAR_VARIANT } from 'settings/constants/ui-control';
import {
  TAC_STEP_INITIAL,
  TAC_INITIAL_RESEND_COUNT,
  MAX_RESEND_COUNT,
  RESEND_COUNT_INCREMENT,
  TAC_STEP_FINAL,
  TAC_STEP_RETRY,
} from 'settings/constants/tac';

import { useTheme } from '@material-ui/core/styles';
import BasicDrawer from '../BasicDrawer';
import Secure2uStepInitialSection from './content/Secure2uStepInitialSection';
import Secure2uStepFinalSection from './content/Secure2uStepFinalSection';

import '../style.scss';
import Secure2uStepRetrySection from './content/Secure2uStepRetry';
import { useWindowSize } from '../../../../utils/transaction';
import { BasicButton } from '../../buttons';

const { PERSISTENT } = SIDEBAR_VARIANT;

const Secure2uDrawer = ({
  className,
  variant,
  currentStep,
  description,
  title,
  timestamp,
  hideBackdrop,
  isOpen,
  onClose,
  onRequestSecure2u,
  onSubmitTac,
  onChange,
  onRetrySecure2u,
  showPopup,
  temporaryClosed,
  backRetrySecure2u,
  prelogin,
}) => {
  const { formatMessage } = useIntl();

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tac: '',
    resendCount: TAC_INITIAL_RESEND_COUNT,
    isDisplay: false,
    isError: false,
    isShowResend: false,
    isShowTimer: false,
  });

  const { tac, isDisplay, isError, isShowResend, isShowTimer, resendCount } = state;

  const handleSubmit = () => {
    // inline validate TAC value before submitting back to app
    if (!isValidTac(tac)) {
      setState({
        isDisplay: false,
        isError: true,
      });
      return;
    }

    onSubmitTac(tac);
  };

  const handleResendTac = () => {
    onRequestSecure2u();

    const updatedResendCount = resendCount + RESEND_COUNT_INCREMENT;
    setState({
      isShowResend: false,
      resendCount: updatedResendCount,
      isShowTimer: updatedResendCount < MAX_RESEND_COUNT,
    });
  };

  const handleClose = () =>
    setState({
      isError: false,
      isDisplay: isOpen,
    });

  const handleTimerComplete = () => setState({ isShowTimer: false, isShowResend: resendCount < MAX_RESEND_COUNT });
  const handleTimerCompleteSecure2u = () => onChange({ tacStep: backRetrySecure2u ? TAC_STEP_INITIAL : TAC_STEP_RETRY });

  useEffect(() => {
    setState({ isDisplay: isOpen });
  }, [isOpen]);

  useEffect(() => {
    // start timer upon TAC requested
    if (currentStep === TAC_STEP_FINAL) {
      setState({ isShowTimer: true });
    }
  }, [currentStep]);

  return (
    <>
      <Drawer
        anchor="bottom"
        variant={currentStep === TAC_STEP_INITIAL && !temporaryClosed && !prelogin ? variant : 'temporary'}
        open={isDisplay}
        onClose={onClose}
        className={`drawer drawer-tac ${className}`}
      >
        <Grid
          container
          justify={currentStep === TAC_STEP_FINAL ? 'space-between' : 'center'}
          className={currentStep === TAC_STEP_FINAL && window.innerWidth > MOBILE_SCREEN_SIZE_PX ? 'ph5 pv1' : ''}
        >
          <Grid container sm={12} md={currentStep === TAC_STEP_FINAL ? 12 : 11} item>
            {currentStep === TAC_STEP_INITIAL && (
              <Secure2uStepInitialSection
                buttonTitle={formatMessage({ id: 'buttonText.confirmationSecure2u' })}
                description={description}
                onClick={onRequestSecure2u}
                title={title}
              />
            )}
            {currentStep === TAC_STEP_FINAL && (
              <Secure2uStepFinalSection
                isShowResend={isShowResend}
                isShowTimer={isShowTimer}
                onChange={e => setState({ tac: e.target.value })}
                onButtonClick={handleSubmit}
                onLinkClick={handleResendTac}
                onTimerComplete={handleTimerCompleteSecure2u}
                showPopup={showPopup}
                value={tac}
                timestamp={timestamp}
                backRetrySecure2u={backRetrySecure2u}
              />
            )}

            {currentStep === TAC_STEP_RETRY && <Secure2uStepRetrySection onClick={onRetrySecure2u} />}
          </Grid>
        </Grid>
      </Drawer>

      <BasicDrawer className="error" isOpen={isError} hideBackdrop={false} onClose={handleClose}>
        <h1 className="white f5 lh-copy mb0 tc">
          <FormattedMessage id="tac.invalid" />
        </h1>
      </BasicDrawer>
    </>
  );
};

Secure2uDrawer.defaultProps = {
  className: '',
  title: '',
  description: '',
  onClose: null,
  onRequestSecure2u: null,
  hideBackdrop: true,
  variant: PERSISTENT,
  onChange: null,
  onRetrySecure2u: null,
  showPopup: null,
  temporaryClosed: false,
  backRetrySecure2u: false,
  prelogin: false,
};

Secure2uDrawer.propTypes = {
  title: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  onRequestSecure2u: PropTypes.func,
  className: PropTypes.string,
  description: PropTypes.node,
  hideBackdrop: PropTypes.bool,
  temporaryClosed: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
  onSubmitTac: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onRetrySecure2u: PropTypes.func,
  showPopup: PropTypes.func,
  backRetrySecure2u: PropTypes.bool,
  prelogin: PropTypes.bool,
};

export default Secure2uDrawer;
