import { CURRENCY_IDR } from 'settings/constants/common';

import {
  FORMAT_NUMBER,
  EMAIL_MAX_CHARS,
  FIELD_TYPE_TEXT,
  FORMAT_ALPHANUMERIC,
  MESSAGE_INPUT_MAX_CHARS,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SELECT,
  FORMAT_FAVOURITE_NICKNAME,
  FAVOURITE_NICKNAME_MAX_LENGTH,
  FIELD_TYPE_AUTOCOMPLETE,
  FORMAT_BENEFICIARY_MESSAGE,
} from 'settings/constants/transaction';

export const INTERBANK_FIELDS = [
  {
    name: 'accountNumber',
    label: 'transaction.transfer.accountNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'accountLength', isValidateRequired: true },
    ],
  },
  {
    name: 'beneficiaryBankLabel',
    label: 'transaction.transfer.beneficiary.bank',
    type: FIELD_TYPE_TEXT,
    readOnly: true,
  },
  {
    name: 'transferAmount',
    label: 'transaction.transfer.amount',
    type: FIELD_TYPE_NUMBER,
    leftLabel: CURRENCY_IDR,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'message',
    label: 'transaction.transfer.sknTransfer.label.message',
    format: FORMAT_BENEFICIARY_MESSAGE,
    type: FIELD_TYPE_TEXT,
    maxLength: MESSAGE_INPUT_MAX_CHARS,
    placeholder: 'transaction.transfer.sknTransfer.label.messagePlaceholder',
  },
  {
    name: 'beneficiaryEmail',
    label: 'transaction.transfer.sknTransfer.label.beneficiaryEmail',
    type: FIELD_TYPE_TEXT,
    maxLength: EMAIL_MAX_CHARS,
    validationRules: [{ type: 'validEmail', isValidateRequired: true }],
  },
];

// Add new favourite from main transfer page
export const INTERBANK_NEW_FAVOURITE = [
  {
    name: 'toAccountNo',
    label: 'transaction.transfer.accountNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'accountLength', isValidateRequired: true },
    ],
  },
  {
    name: 'accountNickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    options: [],
    name: 'bankCode',
    label: 'transaction.transfer.beneficiary.bank',
    placeholder: 'transaction.transfer.swift.placeholder.select',
    type: FIELD_TYPE_AUTOCOMPLETE,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'emailAddress',
    label: 'transaction.transfer.beneficiary.emailOptional',
    type: FIELD_TYPE_TEXT,
    maxLength: EMAIL_MAX_CHARS,
    validationRules: [{ type: 'validEmail', isValidateRequired: true }],
  },
];

export const INTERBANK_EDIT_FAVOURITE = [
  {
    name: 'accountNickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    readOnly: true,
    name: 'bankName',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.transfer.beneficiary.bank',
  },
  {
    readOnly: true,
    name: 'toAccountNo',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.transfer.accountNumber',
  },
  {
    readOnly: true,
    name: 'toAccountHolderName',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.transfer.beneficiary.name',
  },
  {
    name: 'emailAddress',
    label: 'transaction.transfer.beneficiary.emailOptional',
    type: FIELD_TYPE_TEXT,
    maxLength: EMAIL_MAX_CHARS,
    validationRules: [{ type: 'validEmail', isValidateRequired: true }],
  },
];

export const INTERBANK_MODAL_TITLE_EN = 'Transfer to Real Time Transfer';
export const INTERBANK_MODAL_TITLE_ID = 'Transfer to Antarbank';
