export const secure2u = {
  dontClose: 'Do not close this page!',
  makeSureActive:
    'Make sure Secure2u in M2U ID App and notification settings on your device are active.<br/> Please click the notification to confirm your transaction.',
  completeGuide: 'For a Transaction Confirmation Guide,',
  timeStamp: 'Your transaction will be canceled',
  timestamp2: 'on {timestamp}',
  clickHere: 'click here',
  stepOne: 'STEP 1',
  stepTwo: 'STEP 2',
  stepThree: 'STEP 3',
  descriptionOne: 'Open the transaction confirmation by clicking the notification that appears',
  descriptionTwo: 'Verify the transaction. If it is correct, click “Confirm”',
  descriptionThree: 'Enter Secure2u to confirm the transaction',
  cancelTransaction: 'Your transaction will be canceled',
  within: 'within',
  questionRetry: 'Your transaction confirmation has expired. Did not receive a notification? Please ',
  questionRetry2: 'click the ',
  questionRetry3: '<b>"Resend"</b> button.',
  buttonRetry: 'RESEND',
  guideConfirmation: 'Transaction Confirmation Guide',
  titleNotActive: 'You have not yet activated Secure2u.',
  descNotActive:
    'Please ensure that you have the M2U ID App installed on your mobile device. Click the "View Guide" button for password change instructions.',
  buttonHowTo: 'View Guide',
  ok: 'Ok',
};
