import { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import isEmpty from 'lodash/isEmpty';

import { memo } from 'utils/react';
import { get } from 'utils/lodash';

import { useAxiosInterceptor } from 'providers/instance';
import { useRemoteIpInterceptor } from 'providers/remote-ip';

import { putErrorResponse } from 'middleware/actions/error';

import { HTTP_UNAUTHORIZED } from 'settings/constants/response-codes';

import { BasicDrawer } from './drawers';
import Loader from './Loader';

const HttpLoader = () => {
  const { errorResponse } = useSelector(
    state => ({
      errorResponse: state.ErrorReducer.errorResponse,
    }),
    shallowEqual
  );
  const status = get(errorResponse, 'status', '');

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  // NOTE: useAxiosLoader() is global loader used in API fetch on REACT_APP_API_URL
  const [isApiLoading] = useAxiosInterceptor();
  const [isClientIpLoading] = useRemoteIpInterceptor();

  const isLoading = isApiLoading || isClientIpLoading;

  const handleClose = () => {
    dispatch(putErrorResponse({}));
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isEmpty(errorResponse) && status !== HTTP_UNAUTHORIZED) {
      setIsOpen(true);
    }

    if (isEmpty(errorResponse)) setIsOpen(false);

    // we dont show errormessage for 401
    if (status === HTTP_UNAUTHORIZED) {
      dispatch(putErrorResponse({}));
    }
  }, [status, errorResponse]);

  return (
    <>
      <Loader isOpen={isLoading} />

      <BasicDrawer className="error" isOpen={isOpen} hideBackdrop={false} onClose={handleClose}>
        <h1 className="f5 lh-copy tc white">
          <FormattedMessage id="errorMessages.ERR_SERVER_ERROR" />
        </h1>
      </BasicDrawer>
    </>
  );
};

export default memo(HttpLoader);
