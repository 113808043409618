import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putReceiverAccountResult,
  putSenderAccountResult,
  putOwnTransferTac,
  putOwnTransferResult,
  putOwnTransferReceipt,
  putOwnTransferConfirmation,
  putCustomerDetail,
} from 'middleware/actions/transfer';
import { get } from 'utils/lodash';
import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import { formatSourceAccounts } from 'utils/transaction';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';

import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import { RECEIPT_KEYMAP, TRANSFER_KEYMAP } from 'settings/constants/keymap';
import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

const getClientIP = () => remoteIpInstance.get();

const getSenderAccount = ({ body }) => Instance.post(endpoint.get.senderAccount, body);
const getReceiverAccount = ({ body }) => Instance.post(endpoint.get.receiverAccount, body);
const getCustomerDetail = ({ body }) => Instance.post(endpoint.get.customerDetail, body);

const getOwnTransferConfirmation = ({ body }) => Instance.post(endpoint.ownTransfer.getTransferConfirm, body);
const getOwnTransferTac = ({ body }) => Instance.post(endpoint.ownTransfer.requestTac, body);
const getOwnTransferResult = ({ body }) => Instance.post(endpoint.ownTransfer.getTransferResult, body);
const getOwnTransferReceipt = ({ body }) => Instance.post(endpoint.ownTransfer.getReceipt, body);

const getLocale = state => state.LanguageReducer.locale;
const getReceiver = state => state.TransferReducer.receiverAccountResult;

const { SENDER_ACCOUNT, RECEIVER_ACCOUNT, OWN_ACCOUNT, CUSTOMER_DETAIL } = actions.GET;

function* getSenderAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const userId = get(payload, 'userId', '');

    const body = { userId, locale };

    const { data } = yield call(getSenderAccount, { body });

    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const senderAccounts = formatSourceAccounts(get(data, 'fromAccountList', []));
    const transferTypes = get(data, 'transferToMap', []);

    yield put(
      putSenderAccountResult({
        senderAccounts,
        transferTypes,
        responseCode,
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiverAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const userId = get(payload, 'userId', '');

    const body = { userId, locale };
    const { data } = yield call(getReceiverAccount, { body });

    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const serviceInfoBean = get(data, 'serviceInfoBean', {});
    const receiverAccounts = formatSourceAccounts(get(data, 'toAccountList', []));

    yield put(
      putReceiverAccountResult({
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
        responseCode,
        receiverAccounts,
        serviceInfoBean,
        selectedAccount: {},
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCustomerDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const userId = get(payload, 'userId', '');

    const body = { userId, locale };

    const { data } = yield call(getCustomerDetail, { body });

    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const isWna = get(data, 'isWna', false);

    yield put(
      putCustomerDetail({
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
        responseCode,
        isWna,
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getOwnTransferConfirmationSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const renamedPayload = formatKeys(payload, TRANSFER_KEYMAP);

    const body = { locale, ...renamedPayload };

    const { data } = yield call(getOwnTransferConfirmation, { body });

    yield put(putOwnTransferConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getOwnTransferTacSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getOwnTransferTac, { body });

    yield put(putOwnTransferTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getOwnTransferResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const receiver = yield select(getReceiver);
    const { ip } = yield call(getClientIP);

    const toCurrency = get(receiver, 'selectedAccount.currencyCodeIso', '');

    const body = { locale, userAgent, remoteIPAddress: ip, toCurrency, ...payload };

    const { data } = yield call(getOwnTransferResult, { body });

    yield put(putOwnTransferResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getOwnTransferReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };

    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getOwnTransferReceipt, { body });
    const responseCode = get(data, 'responseCode', '');

    yield put(putOwnTransferReceipt({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Transfer() {
  yield all([
    takeLatest(CUSTOMER_DETAIL, getCustomerDetailSaga),
    takeLatest(SENDER_ACCOUNT, getSenderAccountSaga),
    takeLatest(RECEIVER_ACCOUNT, getReceiverAccountSaga),
    takeLatest(OWN_ACCOUNT.TRANSFER_CONFIRMATION, getOwnTransferConfirmationSaga),
    takeLatest(OWN_ACCOUNT.REQUEST_TAC, getOwnTransferTacSaga),
    takeLatest(OWN_ACCOUNT.TRANSFER_RESULT, getOwnTransferResultSaga),
    takeLatest(OWN_ACCOUNT.RECEIPT, getOwnTransferReceiptSaga),
  ]);
}
