import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putLogout,
  putUserAlias,
  putAuthToken,
  putLoginAccessStatus,
  putUserValidationResponse,
  putValidatePasswordResponse,
  putLogoutTransactionSummary,
  putLogoutExpiredSession,
  putExtendSession,
  getLogoutExpiredSession,
  getLogoutTransactionSummary,
  putVerifyCaptchaResetCredential,
  putTacResetCredential,
  putUpdatePasswordResetCredential,
  putCaptchaResetCredential,
  putVerifyTacResetCredential,
  putCheckUserStateForceChangePassword,
  putGenerateTacForceChangePassword,
  putVerifyTacForceChangePassword,
  putCheckUserStateForceChangePhoneNumber,
  putUpdatePhoneNumber,
} from 'middleware/actions/login';

import { get } from 'utils/lodash';
import { encrypt } from 'utils/crypto';

import { putLocale } from 'middleware/actions/language';
import { putErrorResponse } from 'middleware/actions/error';
import {
  putCaptchaImage,
  putCustomerProfile,
  putIsRenewPassword,
  putUpdateCustomerProfile,
  putWelcomeMessage,
} from 'middleware/actions/auth';

import { loginEndpoint as endpoint } from 'providers/endpoints/login';
import { userAgent, fingerPrint, timezone, platform, browser, device } from 'providers/fingerprint';

import { routes } from 'router/routes';
import { DEFAULT_LOCALE } from 'settings/constants/language';
import {
  EXPIRED_SESSION_RESPONSE_CODE,
  SUCCESS_RESPONSE_CODE,
  SUCCESS_RESPONSE_CODE_TAC,
  UNRESPONSIVE_HOST,
} from 'settings/constants/response-codes';
import { isOldCriteriaPasswordLogin } from 'utils/validation';
import { isEmpty } from 'lodash';

const { GET, VALIDATE_USERNAME, VALIDATE_PASSWORD } = actions;

const getClientIP = () => remoteIpInstance.get();

const getLoginAccess = () => Instance.post(endpoint.login.access, {});
const getCustomerInfo = ({ body }) => Instance.post(endpoint.login.getCustomerInfo, body);
const validateUsername = ({ body }) => Instance.post(endpoint.login.validateUsername, body);
const validatePassword = ({ body }) => Instance.post(endpoint.login.authenticateUser, body);
const doLogout = ({ body }) => Instance.post(endpoint.login.logout, body);
const getLogoutTransactionSummaryList = ({ body }) => Instance.post(endpoint.login.logoutTransactionSummary, body);
const doLogoutWithExpiredSession = ({ body }) => Instance.post(endpoint.login.logoutWithExpiredSession, body);
const doExtendSession = ({ body }) => Instance.post(endpoint.login.extendSession, body);
const getCaptchaResetCredential = ({ body }) => Instance.post(endpoint.login.getCaptchaResetCredential, body);
const verifyCaptchaResetCredential = ({ body }) => Instance.post(endpoint.login.verifyCaptchaResetCredential, body);
const getTacResetCredential = ({ body }) => Instance.post(endpoint.login.getTacResetCredential, body);
const verifyTacResetCredential = ({ body }) => Instance.post(endpoint.login.verifyTacResetCredential, body);
const updatePasswordResetCredential = ({ body }) => Instance.post(endpoint.login.updatePasswordResetCredential, body);
const checkUserStateForceChangePassword = ({ body }) => Instance.post(endpoint.login.checkUserForceChangePassword, body);
const generateTacForceChangePassword = ({ body }) => Instance.post(endpoint.login.generateTacForChangePassword, body);
const verifyTacForceChangePassword = ({ body }) => Instance.post(endpoint.login.verifyTacForceChangePassword, body);
const checkUserStateForceChangePhoneNumber = ({ body }) => Instance.post(endpoint.login.checkUserStateChangePhoneNumber, body);
const updatePhoneNumber = ({ body }) => Instance.post(endpoint.login.updatePhoneNumher, body);

const getLocale = state => state.LanguageReducer.locale;
const getUserAlias = state => state.LoginReducer.userAlias;
const customerProfile = state => state.AuthReducer.customerProfile;

function* getLoginAccessSaga() {
  try {
    const { data } = yield call(getLoginAccess);
    const status = get(data, 'serviceInfoBean.status', '');
    yield put(putLoginAccessStatus({ isSuccess: true, status }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export function* getCustomerInfoSaga({ payload, welcomeMessage }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getCustomerInfo, { body });

    const imageLink = get(data, 'imageLink', '');
    const language = get(data, 'locale', DEFAULT_LOCALE);
    const res = {
      ...get(data, 'ibCustProfile', {}),
      language,
      imageLink,
      headerImageLink: get(data, 'captchaCoverImg', ''),
    };

    const putGreetings = welcomeMessage ? [put(putWelcomeMessage(welcomeMessage))] : [];

    yield all([put(putLocale(language)), put(putUpdateCustomerProfile({ ...res })), put(putCaptchaImage(imageLink)), ...putGreetings]);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* validateUsernameSaga({ payload }) {
  try {
    const encryptedName = encrypt(payload);
    const locale = yield select(getLocale);
    const body = { locale, userAlias: encryptedName };
    const { data } = yield call(validateUsername, { body });
    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      const imageLink = get(data, 'imageLink', '');
      const res = {
        imageLink,
        imageCaption: get(data, 'imageCaption', ''),
        isExistingUser: get(data, 'existingUser', false),
        captchaCoverLink: get(data, 'captchaCoverImg', ''),
      };

      yield all([
        put(putUserValidationResponse({ isSuccess: true, res })),
        put(putUserAlias({ userAlias: encryptedName })),
        put(putCaptchaImage(imageLink)),
      ]);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* validatePasswordSaga({ payload }) {
  try {
    const encryptedPassword = encrypt(payload);
    const userAlias = yield select(getUserAlias);
    const locale = yield select(getLocale);
    const timezoneOffset = new Date().getTimezoneOffset();
    const { ip } = yield call(getClientIP);

    const body = {
      locale,
      device,
      browser,
      timezone,
      platform,
      userAlias,
      userAgent,
      fingerPrint,
      timezoneOffset,
      remoteIPAddress: ip,
      userAgentFP: userAgent,
      password: encryptedPassword,
    };

    const { data } = yield call(validatePassword, { body });
    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    if (isSuccess) {
      const jwt = get(data, 'token', '');
      yield put(putAuthToken({ jwt }));
    }

    const language = get(data, 'locale', DEFAULT_LOCALE);
    const languageFromStorage = localStorage.getItem('language');
    const languageLocalStorage =
      !languageFromStorage || isEmpty(languageFromStorage.replace(/"/g, '')) ? locale : languageFromStorage.replace(/"/g, '');
    const res = {
      cifNo: get(data, 'cifNo', ''),
      unreadMsgCount: get(data, 'unreadMsgCount', 0),
      welcomeMessage: get(data, 'welcomeMessage', ''),
      lastLoginDate: get(data, 'lastLoginDateTime', ''),
      customerProfile: { ...get(data, 'ibCustProfile', {}), headerImageLink: get(data, 'captchaCoverImg', ''), language },
      isRenewPassword: isOldCriteriaPasswordLogin(payload),
    };

    yield all([
      put(putValidatePasswordResponse({ responseCode })),
      put(putCustomerProfile({ isSuccess, ...res })),
      put(putLocale(isEmpty(languageLocalStorage) ? locale : languageLocalStorage)),
    ]);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* logoutTransactionSummary() {
  try {
    const locale = yield select(getLocale);
    const { userId } = yield select(customerProfile);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userId, remoteIPAddress };

    const { data } = yield call(getLogoutTransactionSummaryList, { body });
    yield put(putLogoutTransactionSummary(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* logout({ history, stayLanding = false, isMultiSession }) {
  // Stay Landing to force in landing page after renewing password
  try {
    const locale = yield select(getLocale);
    const { userId } = yield select(customerProfile);
    const { data } = yield call(doLogout, { body: { userId } });
    const languageFromStorage = localStorage.getItem('language');
    const languageLocalStorage =
      !languageFromStorage || isEmpty(languageFromStorage.replace(/"/g, '')) ? locale : languageFromStorage.replace(/"/g, '');

    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    if (isSuccess) {
      yield put(putLogout());
      yield put(putIsRenewPassword(false));
      yield put(putLocale(isEmpty(languageLocalStorage) ? locale : languageLocalStorage));

      if (isMultiSession) {
        history.push(routes.logout, { logout: true, isMultiSession, isExpiredSession: false });
        return;
      }

      if (!stayLanding) {
        history.push(routes.logout, { logout: true });
        return;
      }

      history.push(routes.login);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* logoutWithExpiredSession({ history, stayLanding = false }) {
  try {
    const { userId } = yield select(customerProfile);
    const { data } = yield call(doLogoutWithExpiredSession, { body: { userId } });
    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === EXPIRED_SESSION_RESPONSE_CODE;

    if (!isSuccess) {
      yield put(putLogout());
      yield put(getLogoutTransactionSummary());
      return;
    }

    if (isSuccess) {
      yield put(putLogoutExpiredSession());
      yield put(putIsRenewPassword(false));
      if (!stayLanding) {
        history.push(routes.sessionExpired, { logout: true });
        return;
      }
      history.push(routes.login);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* extendSession({ history }) {
  try {
    const { userId } = yield select(customerProfile);
    const { data } = yield call(doExtendSession, { body: { userId } });
    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    if (!isSuccess) {
      yield put(getLogoutTransactionSummary());
      yield put(getLogoutExpiredSession(history));
      return;
    }

    if (isSuccess) {
      yield put(putExtendSession());
      yield put(putIsRenewPassword(false));
      return;
    }
  } catch (error) {
    yield put(putErrorResponse(error));
    yield put(getLogoutExpiredSession(history));
  }
}

function* getCaptchaResetCredentialSaga({ payload = {} }) {
  try {
    const body = { ...payload };
    const { data } = yield call(getCaptchaResetCredential, { body });
    yield put(putCaptchaResetCredential(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* verifyCaptchaResetCredentialSaga({ payload = {} }) {
  try {
    const { userId } = yield select(customerProfile);
    const body = { userId, ...payload };

    const { data } = yield call(verifyCaptchaResetCredential, { body });
    yield put(putVerifyCaptchaResetCredential(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTacResetCredentialSaga({ payload = {} }) {
  try {
    const body = { ...payload };
    const { data } = yield call(getTacResetCredential, { body });

    yield put(putTacResetCredential(data));
  } catch (error) {
    put(putErrorResponse(error));
  }
}

function* verifyTacResetCredentialSaga({ payload = {} }) {
  try {
    const body = { ...payload };
    const { data } = yield call(verifyTacResetCredential, { body });
    yield put(putVerifyTacResetCredential(data));
  } catch (error) {
    put(putErrorResponse(error));
  }
}

function* updatePasswordResetCredentialSaga({ payload = {} }) {
  try {
    const { userId } = yield select(customerProfile);
    const body = { userId, ...payload };

    const { data } = yield call(updatePasswordResetCredential, { body });

    yield put(putUpdatePasswordResetCredential(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* checkUserStateForceChangePasswordSaga({ payload = {} }) {
  const encryptedUserAllias = encrypt(payload.username);
  try {
    const body = { userAlias: encryptedUserAllias, trxId: payload.trxId };
    const { data } = yield call(checkUserStateForceChangePassword, { body });

    yield put(putCheckUserStateForceChangePassword(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* generateTacForceChangePasswordSaga({ payload = {} }) {
  try {
    const body = { ...payload };
    const { data } = yield call(generateTacForceChangePassword, { body });

    yield put(putGenerateTacForceChangePassword(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* verifyTacForceChangePasswordSaga({ payload = {} }) {
  try {
    const body = { ...payload };
    const { data } = yield call(verifyTacForceChangePassword, { body });

    yield put(putVerifyTacForceChangePassword(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* checkUserStateForceChangePhoneNumberSaga({ payload = {} }) {
  const encryptedUserAllias = encrypt(payload.username);

  try {
    const body = { userAlias: encryptedUserAllias, trxId: payload.trxId };
    const { data } = yield call(checkUserStateForceChangePhoneNumber, { body });

    yield put(putCheckUserStateForceChangePhoneNumber(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* updatePhoneNumberSaga({ payload = {} }) {
  try {
    const body = { ...payload };
    const { data } = yield call(updatePhoneNumber, { body });

    yield put(putUpdatePhoneNumber(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Login() {
  yield all([
    takeLatest(GET.LOGOUT, logout),
    takeLatest(GET.LOGOUT_EXPIRED_SESSION, logoutWithExpiredSession),
    takeLatest(GET.LOGOUT_TRANSACTION_SUMMARY, logoutTransactionSummary),
    takeLatest(GET.LOGIN_ACCESS, getLoginAccessSaga),
    takeLatest(GET.CUSTOMER_INFO, getCustomerInfoSaga),
    takeLatest(GET.EXTEND_SESSION, extendSession),
    takeLatest(GET.CAPTCHA_RESET_CREDENTIAL, getCaptchaResetCredentialSaga),
    takeLatest(GET.VERIFY_CAPTCHA_RESET_CREDENTIAL, verifyCaptchaResetCredentialSaga),
    takeLatest(GET.TAC_RESET_CREDENTIAL, getTacResetCredentialSaga),
    takeLatest(GET.VERIFY_TAC_RESET_CREDENTIAL, verifyTacResetCredentialSaga),
    takeLatest(GET.UPDATE_PASSWORD_RESET_CREDENTIAL, updatePasswordResetCredentialSaga),
    takeLatest(GET.CHECK_USER_STATE_FORCE_CHANGE_PASSWORD, checkUserStateForceChangePasswordSaga),
    takeLatest(GET.GENERATE_TAC_FORCE_CHANGE_PASSWORD, generateTacForceChangePasswordSaga),
    takeLatest(GET.VERIFY_TAC_FORCE_CHANGE_PASSWORD, verifyTacForceChangePasswordSaga),
    takeLatest(GET.CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER, checkUserStateForceChangePhoneNumberSaga),
    takeLatest(GET.UPDATE_PHONE_NUMBER, updatePhoneNumberSaga),
    takeLatest(VALIDATE_USERNAME, validateUsernameSaga),
    takeLatest(VALIDATE_PASSWORD, validatePasswordSaga),
  ]);
}
