import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { formatDurationDisplay } from 'utils/date-time';

import { COUNTDOWN_TIMER_INTERVAL, TIMER_DURATION_END, TIMER_DURATION_FORMAT } from 'settings/constants/ui-control';

import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

const CountdownTimer = ({ className, duration, isStart, title, onComplete, format, modalCount, secure2u, secPopup, backRetrySecure2u }) => {
  const durationRef = useRef();

  useEffect(() => {
    if (isStart && durationRef && secure2u) {
      const { current } = durationRef;

      const timerId = setInterval(() => {
        const timerDuration = current.getAttribute('duration');
        const timer = timerDuration - COUNTDOWN_TIMER_INTERVAL;
        const countTextInModal = document.getElementsByClassName('common timer expired ');

        // to get button in modal count
        const objBtnModalCount = document.getElementsByClassName('btn btn-basic');
        const timerSecure2u = document.getElementsByClassName('timer-secure2u');

        const lastItem = Object.keys(objBtnModalCount).pop();
        const buttonOkModalCount = objBtnModalCount[lastItem];

        if (!isEmpty(countTextInModal) && modalCount) {
          buttonOkModalCount.addEventListener('click', () => {
            clearInterval(timerId);
          });
        }

        if (timer === TIMER_DURATION_END || (isEmpty(timerSecure2u) && backRetrySecure2u) || isEmpty(timerSecure2u)) {
          if (secPopup || backRetrySecure2u) {
            return;
          }
          onComplete();
          clearInterval(timerId);
          return;
        }

        current.innerText = formatDurationDisplay(timer, format);
        // timer will not run anymore if use setState
        current.setAttribute('duration', timer);
      }, COUNTDOWN_TIMER_INTERVAL);
    }

    if (isStart && durationRef && !secure2u) {
      const { current } = durationRef;

      const timerId = setInterval(() => {
        const timerDuration = current.getAttribute('duration');
        const timer = timerDuration - COUNTDOWN_TIMER_INTERVAL;
        const countTextInModal = document.getElementsByClassName('common timer expired ');

        // to get button in modal count
        const objBtnModalCount = document.getElementsByClassName('btn btn-basic');
        const lastItem = Object.keys(objBtnModalCount).pop();
        const buttonOkModalCount = objBtnModalCount[lastItem];

        if (!isEmpty(countTextInModal) && modalCount) {
          buttonOkModalCount.addEventListener('click', () => {
            clearInterval(timerId);
          });
        }

        if (timer === TIMER_DURATION_END) {
          onComplete();
          clearInterval(timerId);
          return;
        }

        current.innerText = formatDurationDisplay(timer, format);

        // timer will not run anymore if use setState
        current.setAttribute('duration', timer);
      }, COUNTDOWN_TIMER_INTERVAL);
    }
  }, [isStart, durationRef]);

  return (
    <>
      {modalCount && !secure2u && (
        <span className={`common timer expired ${className}`} ref={durationRef} duration={duration}>
          {formatDurationDisplay(duration, format)}
        </span>
      )}

      {!modalCount && !secure2u && (
        <div className={`common timer ${className}`}>
          <p>{title}</p>
          <p ref={durationRef} duration={duration}>
            {formatDurationDisplay(duration, format)}
          </p>
        </div>
      )}

      {secure2u && (
        <div className="timer-secure2u">
          <span style={{ fontWeight: 300, fontSize: '15px' }}>
            <FormattedMessage id="secure2u.cancelTransaction" />
          </span>
          &nbsp;
          <FormattedMessage id="secure2u.within" />
          &nbsp;
          <span ref={durationRef} duration={duration} style={{ fontSize: '15px' }}>
            {formatDurationDisplay(duration, format)}
          </span>
        </div>
      )}
    </>
  );
};

CountdownTimer.defaultProps = {
  isStart: false,
  onComplete: null,
  className: '',
  title: '',
  format: TIMER_DURATION_FORMAT,
  modalCount: false,
  secure2u: false,
  secPopup: false,
  backRetrySecure2u: false,
};

CountdownTimer.propTypes = {
  className: PropTypes.string,
  isStart: PropTypes.bool,
  onComplete: PropTypes.func,
  title: PropTypes.string,
  format: PropTypes.string,
  duration: PropTypes.number.isRequired, // in milliseconds
  modalCount: PropTypes.bool,
  secure2u: PropTypes.bool,
  secPopup: PropTypes.bool,
  backRetrySecure2u: PropTypes.bool,
};

export default CountdownTimer;
