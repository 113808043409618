import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { sec2uEndpoint as endpoint } from 'providers/endpoints/sec2u';
import Instance from 'providers/instance';

import {
  actions,
  putActivationSec2u,
  putCheckUserSecure2u,
  putActivationSec2uPrelogin,
  putPoolingSecure2u,
  putPoolingSecure2uPrelogin,
  putPushContentSecure2u,
  putPushContentSecure2uPrelogin,
} from 'middleware/actions/sec2u';
import { putErrorResponse } from 'middleware/actions/error';

const activationSec2u = ({ body }) => Instance.post(endpoint.activationSec2u, body);
const getPushContentSecure2u = ({ body }) => Instance.post(endpoint.pushContentSecure2u, body);
const getPoolingSecure2u = ({ body }) => Instance.post(endpoint.poolingSecure2u, body);
const getCheckUserSecure2u = ({ body }) => Instance.post(endpoint.checkUserSecure2u, body);
const activationSec2uPrelogin = ({ body }) => Instance.post(endpoint.activationSec2uPrelogin, body);
const getPushContentSecure2uPrelogin = ({ body }) => Instance.post(endpoint.pushContentSecure2uPrelogin, body);
const getPoolingSecure2uPrelogin = ({ body }) => Instance.post(endpoint.poolingSecure2uPrelogin, body);

const getLocale = state => state.LanguageReducer.locale;
const customerProfile = state => state.AuthReducer.customerProfile;

function* getActivationSec2uSaga() {
  try {
    const { userId } = yield select(customerProfile);
    const body = { userId };
    const { data } = yield call(activationSec2u, { body });
    yield put(putActivationSec2u(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPushContentSecure2uSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getPushContentSecure2u, { body });

    yield put(putPushContentSecure2u(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPoolingSecure2uSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getPoolingSecure2u, { body });

    yield put(putPoolingSecure2u(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCheckUserSecure2uSaga() {
  try {
    const { userId } = yield select(customerProfile);
    const body = { userId };
    const { data } = yield call(getCheckUserSecure2u, { body });
    yield put(putCheckUserSecure2u(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getActivationSec2uPreloginSaga({ payload = {} }) {
  try {
    const body = payload;
    const { data } = yield call(activationSec2uPrelogin, { body });
    yield put(putActivationSec2uPrelogin(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPushContentSecure2uPreloginSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getPushContentSecure2uPrelogin, { body });

    yield put(putPushContentSecure2uPrelogin(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPoolingSecure2uPreloginSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getPoolingSecure2uPrelogin, { body });

    yield put(putPoolingSecure2uPrelogin(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Sec2u() {
  yield all([
    takeLatest(actions.GET.ACTIVATION_SEC2U, getActivationSec2uSaga),
    takeLatest(actions.GET.PUSH_CONTENT_MAYBANK_SECURE2U, getPushContentSecure2uSaga),
    takeLatest(actions.GET.POOLING_MAYBANK_SECURE2U, getPoolingSecure2uSaga),
    takeLatest(actions.GET.CHECK_USER_SECURE2U, getCheckUserSecure2uSaga),
    takeLatest(actions.GET.ACTIVATION_SEC2U_PRELOGIN, getActivationSec2uPreloginSaga),
    takeLatest(actions.GET.PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN, getPushContentSecure2uPreloginSaga),
    takeLatest(actions.GET.POOLING_MAYBANK_SECURE2U_PRELOGIN, getPoolingSecure2uPreloginSaga),
  ]);
}
