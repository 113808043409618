import React from 'react';
import PropTypes from 'prop-types';

import { useSelector, shallowEqual } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { routes } from './routes';

const { dashboard } = routes;

// The default route when user is authenticated is dashboard, if user not autheticated
const PublicRoute = ({ component: Component, coachmarkComponent, handleTutorial, handleCloseCoachmark, ...rest }) => {
  const { isAuthenticated, isRenewPassword } = useSelector(
    state => ({ isAuthenticated: state.AuthReducer.isAuthenticated, isRenewPassword: state.AuthReducer.isRenewPassword }),
    shallowEqual
  );

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && !isRenewPassword ? (
          <Redirect to={dashboard.casa} />
        ) : (
          <>
            <Component handleTutorial={handleTutorial} handleCloseCoachmark={handleCloseCoachmark} {...props} />
            {coachmarkComponent}
          </>
        )
      }
    />
  );
};

PublicRoute.defaultProps = { component: '', coachmarkComponent: null, handleCloseCoachmark: null, handleTutorial: null };

PublicRoute.propTypes = {
  component: PropTypes.elementType,
  coachmarkComponent: PropTypes.object,
  handleTutorial: PropTypes.func,
  handleCloseCoachmark: PropTypes.func,
};

export default PublicRoute;
