export const settings = {
  subHeader: { label: 'Pengaturan Saya' },
  personal: {
    label: 'Pribadi',
    personalInfo: 'Informasi pribadi',
    theme: 'Tema',
    displayName: 'Nama Tampilan',
    name: 'Nama',
    language: 'Bahasa',
  },
  security: {
    label: 'Keamanan',
    labelChangePasswordSecure2u: 'Ganti Kata Sandi',
    labelChangeImagedSecure2u: 'Ganti Gambar Keamanan',
    changePassword: {
      label: 'Ganti Kata Sandi',
      password: 'Kata Sandi',
      currentPassword: 'Kata Sandi Saat Ini*',
      newPassword: 'Kata Sandi Baru*',
      confirmNewPassword: 'Konfirmasi Kata Sandi Baru*',
      enterNewPassword: 'Masukan Kata Sandi Baru',
      enterCurrentPassword: 'Masukkan Kata Sandi Saat Ini',
      description:
        'Gunakan minimal 8 karakter. Jangan gunakan kata sandi dari situs lain, atau sesuatu yang terlalu jelas seperti nama hewan peliharaan Anda.',
      newPasswordCriteria: {
        title: 'Kata Sandi Baru Anda harus: ',
        length: 'Kata sandi harus minimal 8 karakter (maksimal 20)',
        uppercase: 'Harus mengandung setidaknya 1 huruf besar',
        lowercase: 'Harus mengandung setidaknya 1 huruf kecil',
        number: 'Harus mengandung setidaknya 1 nomor',
        space: 'TIDAK boleh mengandung spasi',
        userId: 'Tidak boleh sama dengan User ID Maybank2u Anda',
        previousPassword: 'TIDAK boleh sama dengan 5 kata sandi Anda sebelumnya',
        characters: 'TIDAK boleh mengandung karakter #,$,&',
      },
    },
    securityImage: {
      label: 'Gambar Keamanan',
      description: 'Ubah gambar keamanan anda',
      loadMoreImages: 'Klik untuk lebih banyak gambar',
      guidlines:
        'Silakan pilih gambar di bawah ini. Setelah pengiriman, gambar yang dipilih akan digunakan untuk verifikasi identitas Anda sendiri',
    },
    removeDevice: {
      label: 'Hapus Perangkat',
      device: 'Perangkat',
      deviceName: 'Nama Perangkat',
      secure: 'Secure2U',
      deviceLinked: 'Perangkat Ditautkan',
      labelTitle: 'Hapus perangkat tertaut',
      labelTitleModal: 'Hapus perangkat Anda dari M2U',
      descModal: 'Anda akan menghapus perangkat, apakah Anda ingin melanjutkan?',
      removeDeviceSuccess: 'Data Anda telah diperbarui.',
    },
  },
  theme: { title: 'Pilih tema untuk Web M2U' },
  message: {
    themeChanged: 'Tema berhasil diubah',
    infoChanged: 'Data Anda berhasil diperbaharui',
    passwordChanged: 'Kata sandi anda telah diperbarui',
    securityImageChanged: 'Gambar keamanan anda telah diperbarui',
    currentPassword: 'Ini adalah kata sandi Anda yang sekarang. Silakan coba kata sandi yang lain',
    applyCc: 'Klik di sini untuk mendapatkan Maybank Kartu Kredit yang sesuai dengan kebutuhan Anda dan nikmati berbagai promosi menarik',
  },
  manageTransaction: {
    label: 'Kelola Transaksi',
    recurringTransfer: 'Transfer Berulang',
    laterTransfer: 'Transfer Nanti',
    edit: 'Ubah',
    delete: 'Hapus',
    from: 'dari',
    transferTo: 'Transfer untuk',
    dataNotAvailable: 'Data tidak tersedia',
    tacTitle: 'Please confirm your instruction details.',
    tacDescription: 'To proceed with this instruction, please request and insert your <b>TAC</b> number.',
    recurring: {
      weekly: ' - Berulang Mingguan',
      monthly: ' - Berulang Bulanan',
    },
    resultMessage: {
      recurring: {
        success: 'Penghapusan Transfer Berulang Berhasil',
        error: 'Penghapusan Transfer Berulang Gagal',
      },
    },
    successMessage: {
      recurring: 'Transfer Berulang Berhasil Dihapus',
    },
  },
  errorMessage: {
    confirmationNotMatch: 'Konfirmasi kata sandi tidak cocok',
    displayNameInvalid: 'Nama tampilan harus antara 1 hingga 64 ruang alfanumerik',
  },
};
