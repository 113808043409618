export const actions = {
  GET: {
    ACTIVATION_SEC2U: 'GET_ACTIVATION_SEC2U',
    PUSH_CONTENT_MAYBANK_SECURE2U: 'GET_PUSH_CONTENT_SECURE2U',
    POOLING_MAYBANK_SECURE2U: 'GET_POOLING_SECURE2U',
    CHECK_USER_SECURE2U: 'GET_CHECK_USER_SECURE2U',
    ACTIVATION_SEC2U_PRELOGIN: 'GET_ACTIVATION_SEC2U_PRELOGIN',
    PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN: 'GET_PUSH_CONTENT_SECURE2U_PRELOGIN',
    POOLING_MAYBANK_SECURE2U_PRELOGIN: 'GET_POOLING_SECURE2U_PRELOGIN',
  },
  PUT: {
    ACTIVATION_SEC2U: 'PUT_ACTIVATION_SEC2U',
    PUSH_CONTENT_MAYBANK_SECURE2U: 'PUT_PUSH_CONTENT_SECURE2U',
    POOLING_MAYBANK_SECURE2U: 'PUT_POOLING_SECURE2U',
    CHECK_USER_SECURE2U: 'PUT_CHECK_USER_SECURE2U',
    ACTIVATION_SEC2U_PRELOGIN: 'PUT_ACTIVATION_SEC2U_PRELOGIN',
    PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN: 'PUT_PUSH_CONTENT_SECURE2U_PRELOGIN',
    POOLING_MAYBANK_SECURE2U_PRELOGIN: 'PUT_POOLING_SECURE2U_PRELOGIN',
  },
};
// All
export const getActivationSec2u = body => ({ type: actions.GET.ACTIVATION_SEC2U, payload: body });
export const putActivationSec2u = res => ({ type: actions.PUT.ACTIVATION_SEC2U, res });

// secure2u transaction
export const getPushContentSecure2u = payload => ({ type: actions.GET.PUSH_CONTENT_MAYBANK_SECURE2U, payload });
export const getPoolingSecure2u = payload => ({ type: actions.GET.POOLING_MAYBANK_SECURE2U, payload });
export const getCheckUserSecure2u = payload => ({ type: actions.GET.CHECK_USER_SECURE2U, payload });
export const getActivationSec2uPrelogin = payload => ({ type: actions.GET.ACTIVATION_SEC2U_PRELOGIN, payload });
export const getPushContentSecure2uPrelogin = payload => ({ type: actions.GET.PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN, payload });
export const getPoolingSecure2uPrelogin = payload => ({ type: actions.GET.POOLING_MAYBANK_SECURE2U_PRELOGIN, payload });

export const putPushContentSecure2u = payload => ({ type: actions.PUT.PUSH_CONTENT_MAYBANK_SECURE2U, payload });
export const putPoolingSecure2u = payload => ({ type: actions.PUT.POOLING_MAYBANK_SECURE2U, payload });
export const putCheckUserSecure2u = payload => ({ type: actions.PUT.CHECK_USER_SECURE2U, payload });
export const putActivationSec2uPrelogin = res => ({ type: actions.PUT.ACTIVATION_SEC2U_PRELOGIN, res });
export const putPushContentSecure2uPrelogin = payload => ({ type: actions.PUT.PUSH_CONTENT_MAYBANK_SECURE2U_PRELOGIN, payload });
export const putPoolingSecure2uPrelogin = payload => ({ type: actions.PUT.POOLING_MAYBANK_SECURE2U_PRELOGIN, payload });
