export const secure2u = {
  dontClose: 'Jangan menutup halaman ini!',
  makeSureActive:
    'Pastikan Secure2u di M2U ID App dan pengaturan notifikasi di perangkat Anda aktif.<br/> Silakan klik notifikasi untuk melakukan konfirmasi transaksi Anda.',
  completeGuide: 'Untuk Panduan Konfirmasi Transaksi,',
  timeStamp: 'Transaksi Anda akan dibatalkan',
  timestamp2: 'dalam {timestamp}',
  clickHere: 'klik di sini',
  stepOne: 'LANGKAH 1',
  stepTwo: 'LANGKAH 2',
  stepThree: 'LANGKAH 3',
  descriptionOne: 'Buka konfirmasi transaksi dengan klik notifikasi yang muncul',
  descriptionTwo: 'Verifikasi transaksi. Apabila sudah sesuai, klik “Konfirmasi”',
  descriptionThree: 'Masukkan passcode Secure2u untuk konfirmasi transaksi',
  cancelTransaction: 'Transaksi Anda akan dibatalkan',
  within: 'dalam',
  questionRetry: 'Masa berlaku konfirmasi transaksi Anda sudah berakhir. Apakah Anda tidak ',
  questionRetry2: 'mendapatkan nofitikasi? Silakan klik tombol ',
  questionRetry3: 'tombol <b>"Kirim Ulang"</b>',
  buttonRetry: 'KIRIM ULANG',
  guideConfirmation: 'Panduan Konfirmasi Transaksi',
  titleNotActive: 'Anda belum melakukan aktivasi Secure2u',
  descNotActive:
    'Pastikan Anda sudah memiliki M2U ID App pada Handphone Anda.  Klik tombol “Lihat Panduan” untuk melihat instruksi penggantian password',
  buttonHowTo: 'Lihat Panduan',
  ok: 'Ok',
};
